<template>
  <template v-if="showDetail">
    <sdfl-mnfrt-detail
        :prfmncBasicInfo="prfmncBasicInfo"
        :rawmtrlCrynInfo="rawmtrlCrynInfo"
        :rawmtrlPrcsInfo="rawmtrlPrcsInfo"
        :rawmtrlInpuInfo="rawmtrlInpuInfo"
        :prdctnInfo="prdctnInfo"
        :ntslDtlInfo="ntslDtlInfo"
        :ntslInfo="ntslInfo"
        :crynMtralDtlInfo="crynMtralDtlInfo"
    />
  </template>

  <template v-else-if="!showDetail && parseInt(prfmncYr) <= 2024">
    <by2024-sdfl-mnfrt-update
        :prfmncBasicInfo="prfmncBasicInfo"
        :rawmtrlCrynInfo="rawmtrlCrynInfo"
        :rawmtrlPrcsInfo="rawmtrlPrcsInfo"
        :prdctnInfo="prdctnInfo"
        :ntslDtlInfo="ntslDtlInfo"
        :ntslInfo="ntslInfo"
    />
  </template>

  <template v-else>
    <sdfl-mnfrt-update
        :prfmncBasicInfo="prfmncBasicInfo"
        :rawmtrlCrynInfo="rawmtrlCrynInfo"
        :rawmtrlPrcsInfo="rawmtrlPrcsInfo"
        :rawmtrlInpuInfo="rawmtrlInpuInfo"
        :prdctnInfo="prdctnInfo"
        :ntslDtlInfo="ntslDtlInfo"
        :ntslInfo="ntslInfo"
    />
  </template>
</template>

<script>
import By2024SdflMnfrtUpdate from "@/views/prfmnc-rpt/dtl/sdfl-mnfrt/By2024SdflMnfrtUpdate.vue";
import SdflMnfrtUpdate from "@/views/prfmnc-rpt/dtl/sdfl-mnfrt/SdflMnfrtUpdate.vue";
import SdflMnfrtDetail from "@/views/prfmnc-rpt/dtl/sdfl-mnfrt/SdflMnfrtDetail.vue";
import { storeSwitch } from "@/js/store/store-switch";

const API = {
  BUSINESS_PLACE_INFO: "/bplc",
  PERFORMANCE_BASIC_INFORMATION: "/prfmnc",
  CHECK_PERMITTED_BUSINESS: "/prfmnc/chck-prm-bplc",
  RAW_MATERIALS_CARRY_IN_INFORMATION: "/prfmnc/sdflMnfrt/rawmtrl-cryn-info",
  RAW_MATERIALS_PROCESS_INFORMATION: "/prfmnc/sdflMnfrt/rawmtrl-prcs-info",
  RAW_MATERIALS_INPUT_INFORMATION: "/prfmnc/sdflMnfrt/rawmtrl-input-info",
  PRODUCTION_INFORMATION: "/prfmnc/sdflMnfrt/prdctn-info",
  NET_SALES_DETAILS_INFORMATION: "/prfmnc/sdflMnfrt/ntsl-dtl-info",
  NET_SALES_INFORMATION: "/prfmnc/sdflMnfrt/ntsl-info",
  PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY: "/prfmnc/reg-psblty-idnty", // 실적 신규등록 가능상태 체크
};

const ROUTE = {
  LIST: "/prfmnc-rpt/PrfmncRpt"
};

export default {
  components: {
    By2024SdflMnfrtUpdate,
    SdflMnfrtUpdate,
    SdflMnfrtDetail,
  },
  data() {
    return {
      bplcId: this.$route.query.bplcId || "",
      prfmncId: this.$route.query.prfmncId || "",
      prfmncYr: this.$route.query.prfmncYm?.substring(0, 4),
      prfmncMm: this.$route.query.prfmncYm?.substring(4, 6),
      prfmncYm: this.$route.query.prfmncYm || "",
      detailStatusCodes: ['SUB', 'REC', 'APR'], // 제출, 접수, 승인
      prfmncBasicInfo: {},
      rawmtrlCrynInfo: {},
      rawmtrlPrcsInfo: {},
      rawmtrlInpuInfo: {},
      crynMtralDtlInfo:{},
      prdctnInfo: {},
      ntslDtlInfo: {},
      ntslInfo: {},
      mode: "U",
    }
  },
  computed: {
    showDetail() {
      return this.prfmncId && this.detailStatusCodes.includes(this.prfmncBasicInfo.prfmncSttsCd);
    }
  },
  watch: {
    showDetail(newVal) {
      if (newVal) {
        this.mode = 'R';
      } else {
        this.mode = 'U';
      }
    }
  },
  created() {
    this.initialize();
  },
  mounted() {
  },
  methods: {
    async initialize() {
      storeSwitch.on("loading");
      if(!this.showDetail && Boolean(!this.prfmncId)) await this.registerChk();
      await this.loadBplcInfo();
      await this.loadPrfmncBasicInfo();

      const result = await this.checkPermittedBplc();
      if (!result) {
        alert("허용되지 않은 사업장입니다.");
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return;
      }

      // bplcKndCd 체크 후 리디렉션
      if (this.prfmncBasicInfo.bplcKndCd !== "01-01") {
        this.$router.push(ROUTE.LIST);
        storeSwitch.off("loading");
        return; // 리디렉션 후 나머지 초기화 작업을 중단
      }

      await this.loadRawmtrlCrynInfo();
      await this.loadRawmtrlPrcsInfo();
      await this.loadRawmtrlInputInfo();
      await this.loadPrdctnInfo();
      await this.loadNtslDtlInfo();
      await this.loadNtslInfo();
      storeSwitch.off("loading");
    },
    async registerChk() {
      this.$apiCall.post(
          API.PERFORMANCE_REGISTER_POSIBILITY_IDENTIFY,
          { bplcId : this.bplcId, prfmncYr : this.prfmncYr, prfmncMm : this.prfmncMm },
          () => {},
          (error) => {
            alert(error.message);
            this.$router.push(ROUTE.LIST);
          }
      );
    },
    loadBplcInfo() {
      return new Promise((resolve, reject) => {
        if (!this.bplcId) {
          resolve();
          return;
        }

        this.$apiCall.get(API.BUSINESS_PLACE_INFO,
            { bplcId : this.bplcId },
            data => {
              if (data.result) {
                this.prfmncBasicInfo = {
                  prfmncId: this.prfmncId,
                  bplcId: data.result.bplcId,
                  bplcNm: data.result.bplcNm,
                  bplcKndNm: data.result.bplcKndNm,
                  prfmncYr: this.prfmncYm?.substring(0, 4),
                  prfmncMm: this.prfmncYm?.substring(4, 6),
                  prfmncSttsCd: "IPG",
                  prfmncSttsNm: "미작성",
                  prfmncMdfcnPrmSttsCd: "",
                  bplcKndCd: data.result.bplcKndCd,
                };
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc basic info."));
            }
        );
      });
    },
    loadPrfmncBasicInfo() {
      return new Promise((resolve, reject) => {
        if (!this.prfmncId) {
          resolve();
          return;
        }

        this.$apiCall.get(API.PERFORMANCE_BASIC_INFORMATION,
          { prfmncId : this.prfmncId },
          data => {
            if (data.result.prfmncMst) {
              this.prfmncBasicInfo = data.result.prfmncMst;
              this.prfmncYm = data.result.prfmncMst.prfmncYm;
              this.prfmncYr = data.result.prfmncMst.prfmncYm?.substring(0, 4);
            }
            resolve();
          },
          () => {
            reject(new Error("Failed to load prfmnc basic info."));
          }
        );
      });
    },
    checkPermittedBplc() {
      return new Promise((resolve) => {
        this.$apiCall.get(API.CHECK_PERMITTED_BUSINESS,
            { bplcId: this.prfmncBasicInfo.bplcId },
            data => {
              resolve(data.result);
            },
            () => {
              resolve(false);
            }
        );
      });
    },
    loadRawmtrlCrynInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.RAW_MATERIALS_CARRY_IN_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result) {
                this.rawmtrlCrynInfo = data.result.rawmtrlCrynInfo;
                this.crynMtralDtlInfo = data.result.crynMtralDtlInfo;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load rawmtrl cryn info."));
            }
        );
      });
    },
    loadRawmtrlPrcsInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.RAW_MATERIALS_PROCESS_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result && Object.keys(data.result).length > 0) {
                this.rawmtrlPrcsInfo = data.result;
              } else {
                this.rawmtrlPrcsInfo = {
                  wtCrfqty: 0.00,
                  wtSumCryqty: 0.00,
                  wtSumInpqty: 0.00,
                  wtDscdCrtqty: 0.00,
                  wtRmnqty: 0.00,
                }
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load rawmtrl prcs info."));
            }
        );
      });
    },
    loadRawmtrlInputInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.RAW_MATERIALS_INPUT_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result) {
                this.rawmtrlInpuInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load rawmtrl input info."));
            }
        );
      });
    },
    loadPrdctnInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.PRODUCTION_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result) {
                this.prdctnInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prdctn info."));
            }
        );
      });
    },
    loadNtslDtlInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_DETAILS_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result) {
                this.ntslDtlInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load ntsl dtl info."));
            }
        );
      });
    },
    loadNtslInfo() {
      return new Promise((resolve, reject) => {
        this.$apiCall.get(API.NET_SALES_INFORMATION,
            { prfmncId: this.prfmncId, bplcId: this.prfmncBasicInfo.bplcId, prfmncYm: this.prfmncYm, mode: this.mode },
            data => {
              if (data.result) {
                this.ntslInfo = data.result;
              }
              resolve();
            },
            () => {
              reject(new Error("Failed to load prfmnc basic info."));
            }
        );
      });
    }
  }
}
</script>

<style scoped>

</style>