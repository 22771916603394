<template>

  <h2 class="title1 mt_0">실적보고 수정</h2>
  <div class="board_write">
    <table>
      <caption>실적연도, 실적상태, 사업장명, 사업장종류</caption>
      <colgroup>
        <col style="width:18%">
        <col style="width:32%">
        <col style="width:18%">
        <col style="width:32%">
      </colgroup>
      <tbody>
      <tr>
        <th scope="row">실적연월</th>
        <td>{{ formattedPrfmncYm }}</td>
        <th scope="row">실적상태</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.prfmncSttsNm || "작성중" }}</td>
      </tr>
      <tr>
        <th scope="row">사업장명</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.bplcNm }}</td>
        <th scope="row">사업장종류</th>
        <td>{{ fireRtrvlDtlObj.prfmncMst.bplcKndNm }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div v-show="!showDetail" class="txt_box mt_10">
    <ul class="dot_list">
      <li>실적 등록 시, 반드시 톤 단위로 환산하여 입력 부탁드립니다.</li>
    </ul>
  </div>

  <rjct-rsn-area :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst" />
  <prfmnc-mdfcn-prm-area :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst" @dmnd-rtrcn="dmndRtrcnCmptn" />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="prfmncDtlFireRtrvlWtCrynGridTitle">폐기물 반입 현황</h3>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlWtCrynGrid"
      :dataSource="dataSourceFireRtrvlWtCryn"
      :columns="gridColumnsFireRtrvlWtCryn"
      :gridattr="gridAttrFireRtrvlWtCryn"
      v-model:grid="prfmncFireRtrvlWtCrynGridObject"
      @loaded="gridLoadedFireRtrvlWtCryn"
      :refreshGrid="gridReloadFireRtrvlWtCryn"
      @change="changeFireRtrvlWtCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2">자원순환정보시스템의 소각(처분)사업장 반입처리 등록 현황</h3>
    <div>
      <br-button label="자원순환정보시스템 정보적용" size="sm" format="btn" @click="rsrcRcyclnCrynInfoApply" />
    </div>
  </div>

  <div class="grid_search">
    <span class="txt_point">※ 자원순환정보시스템에 입력된 반입정보입니다.</span>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlRsrcRcyclnCrynGrid"
      :dataSource="dataSourceFireRtrvlRsrcRcyclnCryn"
      :columns="gridColumnsFireRtrvlRsrcRcyclnCryn"
      :gridattr="gridAttrFireRtrvlRsrcRcyclnCryn"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlRsrcRcyclnCrynGridObject"
      @loaded="gridLoadedFireRtrvlRsrcRcyclnCryn"
      :refreshGrid="gridReloadFireRtrvlRsrcRcyclnCryn"
  />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="prfmncDtlFireRtrvlWtTitle">폐기물 처리 현황</h3>
  </div>
  <div class="grid_search">
    <h4>폐기물 처리 현황 <span>※ 전처리량(톤):전처리되어 소각로에 투입되지 않는 폐기물</span></h4>
  </div>
  <div class="board_write thead">
    <table>
      <caption>폐기물 처리 현황</caption>
      <colgroup>
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
        <col style="width: 20%;">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">이월량</th>
        <th scope="col">반입량</th>
        <th scope="col">전처리량</th>
        <th scope="col">투입량</th>
        <th scope="col">잔량</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="이월량">
          <!-- <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" readonly /> -->
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" :readonly="isReadOnlyWtCrfqty" />          
        </td>
        <td class="txt_right" aria-label="반입량">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" readonly required />
        </td>
        <td class="txt_right" aria-label="전처리량">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login"  />
        </td>
        <td class="txt_right" aria-label="투입량">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" readonly required />
        </td>
        <td class="txt_right" aria-label="잔량">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty" :maxlength="15" validator="number" align="right" unit="톤" panel="login" readonly />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h4 class="title3 cursor_pt" tabindex="-1" ref="prfmncDtlFireRtrvlInputTitle" @click="callInfoPopup">
      시설별 폐기물 투입 현황
      <span class="cursor_pt">* 투입량 작성이 불가능한 경우, 반입량으로 작성</span>
      <span class="ref bounce">◀ 저위발열량 입력 정보를 참고하고 싶으시면 클릭하세요.</span>
    </h4>
    <br-button label="반입량을 투입량으로 적용" size="xsm" format="tertiary" @click="wtInpqtyAplcn" />
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlInputGrid"
      :dataSource="dataSourceFireRtrvlInput"
      :columns="gridColumnsFireRtrvlInput"
      :gridattr="gridAttrFireRtrvlInput"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlInputGridObject"
      @loaded="gridLoadedFireRtrvlInput"
      :refreshGrid="gridReloadFireRtrvlInput"
      @change="changeFireRtrvlInput"
  />

  <div class="board_info mt_40">
    <h4 class="title3 cursor_pt" tabindex="-1" ref="asstFuelTitle" @click="callEnergyPopup">시설별 보조연료 투입 현황</h4>
    <div>
      <br-button label="행 추가" size="xsm" format="primary line mr_5" @click="addAsstFuelInput" />
      <br-button label="행 삭제" size="xsm" format="point line" @click="deleteAsstFuelInput" />
    </div>
  </div>

  <div class="grid_search">
    <h4>시설별 보조연료 투입 현황 <span>※ 보조연료 투입량은 월별 누계값을 입력 하셔야 합니다.</span></h4>
  </div>

  <sb-grid
      id="asstFuelInputGrid"
      :dataSource="asstFuelInputDataSource"
      :columns="asstFuelInputGridColumns"
      :gridattr="asstFuelInputGridAttr"
      v-model:grid="asstFuelInputGridObject"
      @loaded="asstFuelInputGridLoaded"
      :refreshGrid="asstFuelInputGridReload"
      @change="changeAsstFuelInput"
  />


  <div class="board_info mt_40" style="display: flow ">
    <input type="checkbox" id="fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn" label="에너지 회수,이용량 계측불가 시설일 경우 체크" class="checkbox_30"
           v-model="fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn"
    /><label for="계측불가여부" class="label_23">  ※ 에너지 회수,이용량 계측 불가 시설일 경우 클릭해 주세요.</label>
    <br>
    <textarea type="textarea" class="textarea_10"
              ref="exclRsnCn"
              v-if="fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn === true || fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn === 'true'"
              v-model="fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnCn"
              style="width: 1275px; height: 154px;"
              value="" placeholder="계측불가 사유를 입력해 주세요."/>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">
      에너지 회수 현황
      <span>* 전기이용량은 1MWh = 0.86Gcal로 환산됩니다.</span>
    </h3>

  </div>
  <div class="grid_search">
    <h4><span>※ 총 에너지 발생가능량(Gcal)은 저위발열량 값을 이용하여 자동 계산되며 저위발열량은 시설별 폐기물 투입 현황에서 입력할 수 있습니다.</span></h4>
  </div>
  <div class="board_list">
    <table>
      <caption>에너지 회수 현황</caption>
      <colgroup>
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
        <col :style="{width: `calc(100% / 6)`}">
      </colgroup>
      <thead>
      <tr>
        <th scope="col">총 에너지 발생가능량<br />(Gcal)</th>
        <th scope="col">총 에너지 회수량(Gcal)<br />(X + Y)</th>
        <th scope="col">에너지 발생량(Gcal)<br />(X)</th>
        <th scope="col">미이용(Gcal)<br />(Y)</th>
        <th scope="col">에너지 이용량(Gcal)<br />(A + B + C + D + E + F)</th>
        <th scope="col">에너지 이용효율(%)</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="txt_right" aria-label="총 에너지 발생가능량(Gcal)">
          <br-form-input ref="eneOcrnPsbltyQtyInput" label="" v-model="totalEneOcrnPsbltyQty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_right" aria-label="총 에너지 회수량 (Gcal) (X + Y)">
          <br-form-input ref="eneRtrqtyInput" label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly required />
        </td>
        <td class="txt_right" aria-label="에너지 발생량(Gcal) (X)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.eneOcrnQtyTotal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_right" aria-label="미이용(Gcal) (Y)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" />
        </td>
        <td class="txt_right" aria-label="에너지 이용량(Gcal) (A + B + C + D + E + F)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.eneutzQtyTotal" :maxlength="15" validator="number" align="right" unit="Gcal" panel="login" readonly />
        </td>
        <td class="txt_right" aria-label="에너지 이용효율(%)">
          <br-form-input label="" v-model="fireRtrvlDtlObj.prfmncFireRtrvl.eneUtztnEfcnc" :maxlength="15" validator="number" align="right" unit="%" panel="login" readonly />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="board_info mt_40">
    <h3 class="title2">
      에너지 생산 현황
      <span>* 생산되는 증기/온수/전기 (중복불가)</span>
    </h3>
  </div>

  <div class="grid_search">
    <h4>에너지 생산 현황 <span>※ 증기생산량(Gcal)값은 증기엔탈피와 증기생산량(톤)을 이용하여 자동환산 되며, 자동환산이 완료된 후 증기생산량(Gcal)값을 직접 수정할 수 있습니다. 증기엔탈피는 마이오피스에서 수정가능합니다.</span></h4>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlPrdctnGrid"
      :dataSource="dataSourceFireRtrvlPrdctn"
      :columns="gridColumnsFireRtrvlPrdctn"
      :gridattr="gridAttrFireRtrvlPrdctn"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlPrdctnGridObject"
      @loaded="gridLoadedFireRtrvlPrdctn"
      :refreshGrid="gridReloadFireRtrvlPrdctn"
      @change="changeFireRtrvlPrdctn"
  />

  <div class="board_info mt_40">
    <h3 class="title2" tabindex="-1" ref="prfmncDtlFireRtrvlUseTitle">
      에너지 이용 현황
      <span>* 증기로 발전과 단순열발전 중복입력 주의</span>
    </h3>
  </div>

  <sb-grid
      id="prfmncDtlFireRtrvlUseGrid"
      :dataSource="dataSourceFireRtrvlUse"
      :columns="gridColumnsFireRtrvlUse"
      :gridattr="gridAttrFireRtrvlUse"
      :param="fireRtrvlDtlObj.prfmncMst"
      v-model:grid="prfmncFireRtrvlUseGridObject"
      @loaded="gridLoadedFireRtrvlUse"
      :refreshGrid="gridReloadFireRtrvlUse"
      @change="changeFireRtrvlUse"
  />

  <h3 class="title2">에너지 외부판매 현황</h3>
  <div class="grid_search">
    <h4>에너지 외부판매 현황 <span>※ 외부판매량은 해당 월 데이터만 입력 하셔야 합니다.</span></h4>
  </div>
  <sb-grid
      id="prfmncFireRtrvlNtslGrid"
      :dataSource="dataSourceFireRtrvlNtsl"
      :columns="gridColumnsFireRtrvlNtsl"
      :gridattr="gridAttrFireRtrvlNtsl"
      v-model:grid="prfmncFireRtrvlNtslGridObject"
      @loaded="gridLoadedFireRtrvlNtsl"
      :refreshGrid="gridReloadFireRtrvlNtsl"
      @change="changeFireRtrvlNtsl"
  />

  <!-- 실적상태이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-stts-hstry v-if="fireRtrvlDtlObj.prfmncMst.prfmncId" :prfmncId="fireRtrvlDtlObj.prfmncMst.prfmncId" />

  <!-- 수정허용요청 이력 현황 (신규등록인 경우 노출안함) -->
  <prfmnc-mdfcn-prm-hstry v-if="fireRtrvlDtlObj.prfmncMst.prfmncId" :prfmncId="fireRtrvlDtlObj.prfmncMst.prfmncId" />

  <!-- 버튼 -->
  <common-button-area
      :prfmncBasicInfo="fireRtrvlDtlObj.prfmncMst"
      @save="prtmncRptDtlSaveChk"
      @submission="prtmncRptDtlSaveChk(true)"
  />

  <fire-rtrvl-prcs-prfmnc-chg-dscnt-popup @eblc-aplcn="prtmncRptDtlSave" />
  <info-popup></info-popup>
  <energy-popup></energy-popup>
</template>

<script>
import SbGrid from "@/components/SbGrid.vue";
import utils from "@/js/utils/utils";
import commonButtonArea from "@/views/prfmnc-rpt/dtl/components/CommonButtonArea.vue";
import prfmncSttsHstry from "@/views/prfmnc-rpt/components/PrfmncSttsHstry.vue";
import PrfmncMdfcnPrmHstry from "@/views/prfmnc-rpt/components/PrfmncMdfcnPrmHstry.vue";
import FireRtrvlPrcsPrfmncChgDscntPopup from "@/views/prfmnc-rpt/dtl/components/popup/FireRtrvlPrcsPrfmncChgDscntPopup.vue";
import {storeSwitch} from "@/js/store/store-switch";
import PrfmncMdfcnPrmArea from "@/views/prfmnc-rpt/dtl/components/PrfmncMdfcnPrmArea.vue";
import RjctRsnArea from "@/views/prfmnc-rpt/dtl/components/RjctRsnArea.vue";
import infoPopup from "@/views/prfmnc-rpt/dtl/fire-rtrvl/popup/infoPopup.vue";
import energyPopup from "@/views/prfmnc-rpt/dtl/fire-rtrvl/popup/energyPopup.vue";

const ROUTE = {
  PERFORMANCE_LIST: "/prfmnc-rpt/PrfmncRpt",
  PERFORMANCE_DTL: "/prfmnc-rpt/dtl/FireRtrvl"
};

const API = {
  PRFMNC_DTL_SAVE: "/prfmnc/fireRtrvl", // 실적 매립가스 상세저장
  USE_PROCESS_PERFORMANCE_CHANGE_CHECK: "/prfmnc/fireRtrvl/prcs-chg-chck",
};

const CODE = {
  PERFORMANCE_STATUS: "PFM001", // 실적상태코드
  ASSISTANCE_FUEL_KIND_GROUP: "PFM007", // 보조연료종류그룹코드
  ASSISTANCE_FUEL_KIND: "PFM008", // 보조연료종류코드
  ASSISTANCE_FUEL_UNIT: "PFM009", // 보조연료단위코드
  LOW_CALORIFIC_POWER_UNIT: "PFM010", // 저위발열량단위코드
};

export default {
  components: {
    SbGrid,
    commonButtonArea,
    prfmncSttsHstry,
    PrfmncMdfcnPrmHstry,
    FireRtrvlPrcsPrfmncChgDscntPopup,
    PrfmncMdfcnPrmArea,
    RjctRsnArea,
    infoPopup,
    energyPopup
  },
  props: ['prfmncFireRtrvl', 'prvPrfmncFireRtrvl', 'prfmncFireRtrvlPrdctnList', 'prfmncFireRtrvlWtCrynList', 'prfmncFireRtrvlRsrcRcyclnList', 'prfmncFireRtrvlNtslList', 'prfmncFireRtrvlWtInputList', 'prfmncMst', 'prfmncFclt', 'asstFuelInputInfo', 'showDetail'],
  data() {
    return {
      prfmncSttsList: [], // 실적상태코드

      updatedWtCrynData: [], // 폐기물반입현황 수정된 행
      insertedWtCrynData: [], // 폐기물반입현황 추가된 행

      isVaporDiff: true, // 에너지이용현황(증기-외부판매톤의 합계) <-> 에너지외부판매현황(외부판매량-증기 합계) 일치여부
      isHtwtrDiff: true, // 에너지이용현황(온수-외부판매의 합계) <-> 에너지외부판매현황(외부판매량-온수의 합계) 일치여부
      isPrvtmDiff: true, // 에너지이용현황(전기-외부판매의 합계) <-> 에너지외부판매현황(외부판매량-전기의 합계) 일치여부

      isUpdating: false,

      // 실적 매립가스 상세정보 데이터
      fireRtrvlDtlObj:{
        bplcDschrgWstqtyTotal: 0, // [시설별폐기물투입현황] 배출사업장계 합계
        hmeLifeWstqtyTotal: 0, // [시설별폐기물투입현황] 생활폐기물 합계
        bplcLifeWstqtyTotal: 0, // [시설별폐기물투입현황] 사업장폐기물 합계
        largeWstqtyTotal: 0, // [시설별폐기물투입현황] 대형폐기물 합계
        wstsrtWstqtyTotal: 0, // [시설별폐기물투입현황] 폐합성수지류 합계
        wstwdtWstqtyTotal: 0, // [시설별폐기물투입현황] 폐목재 합계
        wstsftWstqtyTotal: 0, // [시설별폐기물투입현황] 폐섬유 합계
        wstvnlWstqtyTotal: 0, // [시설별폐기물투입현황] 영농폐비닐 합계
        etcWstqtyTotal: 0, // [시설별폐기물투입현황] 기타 합계

        vaporPrdqtyTotal: 0,     // [에너지발생량(Gcal) (X) > 증기 합계
        htwtrPrdqtyTotal: 0,     // [에너지발생량(Gcal) (X) > 온수 합계
        prvtmPrdqtyTotal: 0,     // [에너지발생량(Gcal) (X) > 전기 합계

        vaporUsqtyTotal: 0, // [에너지이용현황] 증기(증기톤) > 자체이용(Gcal) 항목값 합계
        vaporNsqtyTotal: 0, // [에너지이용현황] 증기(증기톤) > 외부판매(Gcal) 항목값 합계
        htwtrUsqtyTotal: 0, // [에너지이용현황] 온수 > 온수 자체이용(Gcal) 항목값 합계
        htwtrNsqtyTotal: 0, // [에너지이용현황] 온수 > 온수 외부판매(Gcal) 항목값 합계
        prvtmUsqtyTotal: 0, // [에너지이용현황] 전기 > 전기 자체이용(MWh) 항목값 합계
        prvtmNsqtyTotal: 0, // [에너지이용현황] 전기 > 전기 외부판매(MWh) 항목값 합계

        prvtmMWhToGcalTotal: 0,  // [에너지이용현황] 전기 > (전기 자체이용(MWh) + 전기 > 전기 외부판매(MWh)) * 0.86 합계

        wtStotCryqtyTotal: 0, // 반입량 합계
        elctgnQtyTotal: 0, // 발전량 합계 (자체사용량 + 외부판매량 + 기타)
        wtInpqtyTotal: 0, // 소각처리량 합계
        eneOcrnQtyTotal: 0, // 에너지발생량 합계
        eneutzQtyTotal: 0, // 에너지이용량 합계
        totalGasStotNsqty: 0, // 메탄가스 총 판매량 합계

        prfmncMst: {},
        prfmncFireRtrvlWtCrynList : [], // 폐기물반입현황
        prfmncFireRtrvlNtslList : [], // 소각판매현황
        prfmncFireRtrvl: {
          wtCrfqty: 0, // 폐기물이월량
          wtSumCryqty: 0, // 폐기물합계반입량
          prprcsWstqty: 0, // 전처리폐기물량
          wtSumInpqty: 0, // 폐기물합계투입량
          wtRmnqty: 0, // 폐기물잔량
          fireAvgLcp: 0, // 소각평균저위발열량
          eneRtrqty: 0, // 에너지회수량
          eneUnuqty: 0, // 에너지미이용량
          eneUtztnEfcnc: 0, // 에너지이용효율
          stotUsqty: 0, // 소계사용량
          htsrcUsqty: 0, // 열원사용량
          etcUsgUsqty: 0, // 기타용도사용량
          elctgnUsgUsqty: 0, // 발전용도사용량
          stotNsqty: 0, // 소계판매량
          htsrcSumNsqty: 0, // 열원합계판매량
          etcUsgSumNsqty: 0, // 기타용도합계판매량
          elctgnUsgNsqty: 0, // 발전용도판매량
          elctgnEquipInpqty: 0, // 발전설비투입량
          prvtmSumNsqty: 0, // 전기합계판매량
          prvtmSumUsqty: 0, // 전기합계사용량
          etcPrvtmUsqty: 0, // 기타전기사용량
          oilAsstFuelUsqty: 0, // 유류보조연료사용량
          lngAsstFuelUsqty: 0, // LNG보조연료사용량
          etcAsstFuelUsqty: 0, // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0, // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0, // 생산전기보조연료사용량
          exclRsnYn: false, // 제외사유여부
          exclRsnCn:"" // 제외사유내용
        }, // 매립가스실적 (메탄가스생산현황, 메탄가스사용현황)
        prvPrfmncFireRtrvl: {
          wtCrfqty: 0, // 폐기물이월량
          wtSumCryqty: 0, // 폐기물합계반입량
          prprcsWstqty: 0, // 전처리폐기물량
          wtSumInpqty: 0, // 폐기물합계투입량
          wtRmnqty: 0, // 폐기물잔량
          fireAvgLcp: 0, // 소각평균저위발열량
          eneRtrqty: 0, // 에너지회수량
          eneUnuqty: 0, // 에너지미이용량
          eneUtztnEfcnc: 0, // 에너지이용효율
          stotUsqty: 0, // 소계사용량
          htsrcUsqty: 0, // 열원사용량
          etcUsgUsqty: 0, // 기타용도사용량
          elctgnUsgUsqty: 0, // 발전용도사용량
          stotNsqty: 0, // 소계판매량
          htsrcSumNsqty: 0, // 열원합계판매량
          etcUsgSumNsqty: 0, // 기타용도합계판매량
          elctgnUsgNsqty: 0, // 발전용도판매량
          elctgnEquipInpqty: 0, // 발전설비투입량
          prvtmSumNsqty: 0, // 전기합계판매량
          prvtmSumUsqty: 0, // 전기합계사용량
          etcPrvtmUsqty: 0, // 기타전기사용량
          oilAsstFuelUsqty: 0, // 유류보조연료사용량
          lngAsstFuelUsqty: 0, // LNG보조연료사용량
          etcAsstFuelUsqty: 0, // 기타보조연료사용량
          crynPrvtmAsstFuelUsqty: 0, // 반입전기보조연료사용량
          prdctnPrvtmAsstFuelUsqty: 0, // 생산전기보조연료사용량
        }, // 전월 매립가스실적
        asstFuelInputInfo: {}, // [보조연료 투입현황]
      },


      /**************** S : 폐기물반입현황 Grid ****************/
      dataSourceFireRtrvlWtCryn: [],
      gridColumnsFireRtrvlWtCryn: [],
      gridAttrFireRtrvlWtCryn: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '폐기물반입현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncFireRtrvlWtCrynGridObject: null,
      gridReloadFireRtrvlWtCryn: false,
      /**************** E : 폐기물반입현황 Grid ****************/

      /**************** S : 자원순환정보시스템 매립사업장 반입처리 등록현황 Grid ****************/
      dataSourceFireRtrvlRsrcRcyclnCryn: [],
      gridColumnsFireRtrvlRsrcRcyclnCryn: [],
      gridAttrFireRtrvlRsrcRcyclnCryn: {
        checkable: false,
        pageable: false,
        editable : false,
        showRowNo : false,
        showStatus:false,
        toolBar:[],
      },
      prfmncFireRtrvlRsrcRcyclnCrynGridObject: null,
      gridReloadFireRtrvlRsrcRcyclnCryn: false,
      /**************** E : 자원순환정보시스템 매립사업장 반입처리 등록현황 Grid ****************/

      /**************** S : 시설별 폐기물 투입 현황 Grid ****************/
      dataSourceFireRtrvlInput: [],
      gridColumnsFireRtrvlInput: [],
      gridAttrFireRtrvlInput: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '시설별 폐기물 투입현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: false,
        },
        showStatus:true
      },
      prfmncFireRtrvlInputGridObject: null,
      gridReloadFireRtrvlInput: false,
      /**************** E : 시설별 폐기물 투입 현황 Grid ****************/

      /**************** S : 보조연료 투입 현황 Grid ****************/
      asstFuelInputGridReload: false,
      asstFuelInputGridObject: null,
      asstFuelInputDataSource: [],
      asstFuelInputGridColumns: [],
      asstFuelInputGridAttr: {
        showRowNo: false,
        checkable: true,
        pageable: false,
        editable: {eventType: 'mouseup'},
        toolBar: [],
        hideDeleted: true,
        showStatus:true
      },
      /**************** E : 보조연료 투입 현황 Grid ****************/

      /**************** S : 에너지생산 현황 Grid ****************/
      dataSourceFireRtrvlPrdctn: [],
      gridColumnsFireRtrvlPrdctn: [],
      gridAttrFireRtrvlPrdctn: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '에너지생산현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncFireRtrvlPrdctnGridObject: null,
      gridReloadFireRtrvlPrdctn: false,
      /**************** E : 에너지생산 현황 Grid ****************/

      /**************** S : 에너지이용 현황 Grid ****************/
      dataSourceFireRtrvlUse: [],
      gridColumnsFireRtrvlUse: [],
      gridAttrFireRtrvlUse: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '에너지이용현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncFireRtrvlUseGridObject: null,
      gridReloadFireRtrvlUse: false,
      /**************** E : 에너지이용 현황 Grid ****************/

      /**************** S : 에너지 외부판매 Grid ****************/
      dataSourceFireRtrvlNtsl: [],
      gridColumnsFireRtrvlNtsl: [],
      gridAttrFireRtrvlNtsl: {
        checkable: false,
        pageable: false,
        editable: {eventType: 'mouseup'},
        showRowNo : false,
        excelExport: {
          fileName: '에너지외부판매현황(' + this.prfmncMst.bplcKndNm + ').xlsx',
          includeHeader: true,
          includeFormula: true,
        },
        showStatus:true
      },
      prfmncFireRtrvlNtslGridObject: null,
      gridReloadFireRtrvlNtsl: false,
      /**************** E : 에너지 외부판매 Grid ****************/

    };
  },
  computed: {
    /**
     * [에너지회수현황 - 총 에너지 발생가능량(Gcal)]
     * (저위발열량 가중치평균 * 투입량 합계) / 1000
     */
    totalEneOcrnPsbltyQty() {
      const totalEneOcrnPsbltyQty = (parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp) * parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty)) / 1000;
      return parseFloat(totalEneOcrnPsbltyQty.toFixed(2));
    },
    /**
     * [실적보고상세 - 실적연월]
     * YYYY-MM 포맷처리
     */
    formattedPrfmncYm() {
      return (this.fireRtrvlDtlObj.prfmncMst.prfmncYm?.substring(0, 4) ?? "") + "-" + (this.fireRtrvlDtlObj.prfmncMst.prfmncYm?.substring(4, 6) ?? "");
    },

    /**
     * [메탄가스사용현황 - 총 이용량]
     * 발전 + 냉난방 + 가스공급 + 차량연료 + 기타
     */
    gasStotUsqty() {
      const total = (
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.elctgnUsgGasUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.cdysmUsgGasUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.gasSplyUsgGasUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.vhclFuelUsgGasUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgGasUsqty)
      ) ?? 0;
      return parseFloat(total.toFixed(2));
    },
    showExclRsnCn() {
      return !!this.fireRtrvlDtlObj?.prfmncFireRtrvl?.exclRsnYn; // undefined 방지
    },

    isExclRsnCnVisible() { // showExclRsnCn → isExclRsnCnVisible로 변경
      return !!this.fireRtrvlDtlObj?.prfmncFireRtrvl?.exclRsnYn;
    },
    isReadOnlyWtCrfqty(){
      //2025.04.04
      const isReadOnly = this.fireRtrvlDtlObj.prfmncMst.prfmncYm == "202501" ? false : true;

      return isReadOnly;
    }
  },
  watch: {
    prfmncMst: {
      handler(newVal) {
        this.fireRtrvlDtlObj.prfmncMst = {...this.fireRtrvlDtlObj.prfmncMst, ...newVal};
      },
      immediate: true,
      deep:true,
    },
    prfmncFireRtrvl() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl = {...this.fireRtrvlDtlObj.prfmncFireRtrvl, ...this.prfmncFireRtrvl};
    },
    prvPrfmncFireRtrvl() {
      this.fireRtrvlDtlObj.prvPrfmncFireRtrvl = {...this.fireRtrvlDtlObj.prvPrfmncFireRtrvl, ...this.prvPrfmncFireRtrvl};

      // 신규 등록(실적아이디 존재여부로 구분)을 하려는 상태인 경우 폐기물처리현황의 이월량은 전월의 실적잔량을 가져온다.
      if(!this.fireRtrvlDtlObj.prfmncMst.prfmncId){
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty = this.fireRtrvlDtlObj.prvPrfmncFireRtrvl.wtRmnqty;
      }
    },
    prfmncFireRtrvlWtInputList() {
      this.dataSourceFireRtrvlInput = { data: this.prfmncFireRtrvlWtInputList, schema: { id: 'fcltSqno'}};
      this.initializePrfmncFireRtrvlInputGridColumns();
      const itemCount = this.prfmncFireRtrvlWtInputList.length;
      this.gridAttrFireRtrvlInput.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlInput = true;

      // 시설별폐기물투입현황의 투입량합계를 계산해서 폐기물처리현황의 투입량 항목에 적용
      this.fireRtrvlDtlObj.bplcDschrgWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.bplcDschrgWstqty || 0), 0);
      this.fireRtrvlDtlObj.hmeLifeWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.hmeLifeWstqty || 0), 0);
      this.fireRtrvlDtlObj.bplcLifeWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.bplcLifeWstqty || 0), 0);
      this.fireRtrvlDtlObj.largeWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.largeWstqty || 0), 0);
      this.fireRtrvlDtlObj.wstsrtWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.wstsrtWstqty || 0), 0);
      this.fireRtrvlDtlObj.wstwdtWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.wstwdtWstqty || 0), 0);
      this.fireRtrvlDtlObj.wstsftWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.wstsftWstqty || 0), 0);
      this.fireRtrvlDtlObj.wstvnlWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.wstvnlWstqty || 0), 0);
      this.fireRtrvlDtlObj.etcWstqtyTotal = this.prfmncFireRtrvlWtInputList.reduce((acc, item) => acc + (item.etcWstqty || 0), 0);

      this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty = this.fireRtrvlDtlObj.bplcDschrgWstqtyTotal +
          this.fireRtrvlDtlObj.hmeLifeWstqtyTotal +
          this.fireRtrvlDtlObj.bplcLifeWstqtyTotal +
          this.fireRtrvlDtlObj.largeWstqtyTotal +
          this.fireRtrvlDtlObj.wstsrtWstqtyTotal +
          this.fireRtrvlDtlObj.wstwdtWstqtyTotal +
          this.fireRtrvlDtlObj.wstsftWstqtyTotal +
          this.fireRtrvlDtlObj.wstvnlWstqtyTotal +
          this.fireRtrvlDtlObj.etcWstqtyTotal;

      // 시설별폐기물투입현황의 저위발열량 평균값
      const filteredItems = this.prfmncFireRtrvlWtInputList.filter(item => item.fireLcp > 0);
      const sumWstqty = filteredItems.reduce((total, item) => {
        const totalWstqty =
            (item.bplcDschrgWstqty || 0) +
            (item.hmeLifeWstqty || 0) +
            (item.bplcLifeWstqty || 0) +
            (item.largeWstqty || 0) +
            (item.wstsrtWstqty || 0) +
            (item.wstwdtWstqty || 0) +
            (item.wstsftWstqty || 0) +
            (item.wstvnlWstqty || 0) +
            (item.etcWstqty || 0);
        return total + totalWstqty;
      }, 0);

      const sumWstqtyLcp = filteredItems.reduce((total, item) => {
        const totalWstqty =
            (item.bplcDschrgWstqty || 0) +
            (item.hmeLifeWstqty || 0) +
            (item.bplcLifeWstqty || 0) +
            (item.largeWstqty || 0) +
            (item.wstsrtWstqty || 0) +
            (item.wstwdtWstqty || 0) +
            (item.wstsftWstqty || 0) +
            (item.wstvnlWstqty || 0) +
            (item.etcWstqty || 0);
        const fireLcp = item.fireLcp || 0;
        return total + (totalWstqty * fireLcp);
      }, 0);

      this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp = sumWstqty > 0 ? utils.round2(sumWstqtyLcp / sumWstqty) : 0;
    },
    prfmncFireRtrvlPrdctnList() {

      this.dataSourceFireRtrvlPrdctn = this.prfmncFireRtrvlPrdctnList;
      this.initializePrfmncFireRtrvlPrdctnGridColumns();
      const itemCount = this.prfmncFireRtrvlPrdctnList.length;
      this.gridAttrFireRtrvlPrdctn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlPrdctn = true;

      // ********************************************************************************************** 박재훈 수정해야함
      // 화면 초기 진입 시 에너지생산현황의 증기생산량(Gcal) 합계값을 에너지회수현황의 에너지발생량(Gcal) 항목에 적용
      // 증기 + 온수 + 전기 다 합산해서 표시해야 함.
      this.fireRtrvlDtlObj.vaporPrdqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.vaporPrdqty || 0), 0);
      this.fireRtrvlDtlObj.htwtrPrdqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.htwtrPrdqty || 0), 0);
      this.fireRtrvlDtlObj.prvtmPrdqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.prvtmPrdqty || 0), 0);

      this.fireRtrvlDtlObj.prvtmPrdqtyTotal = parseFloat(this.fireRtrvlDtlObj.prvtmPrdqtyTotal * 0.86);

      this.fireRtrvlDtlObj.eneOcrnQtyTotal = this.sanitizeFloatingPointError(this.fireRtrvlDtlObj.vaporPrdqtyTotal + this.fireRtrvlDtlObj.htwtrPrdqtyTotal + this.fireRtrvlDtlObj.prvtmPrdqtyTotal);

      this.dataSourceFireRtrvlUse = this.prfmncFireRtrvlPrdctnList;
      this.initializePrfmncFireRtrvlUseGridColumns();
      this.gridAttrFireRtrvlUse.height = Math.max((itemCount * 30) + 250, 270);
      this.gridReloadFireRtrvlUse = true;

      // 화면 초기 진입 시 에너지이용현황의 증기(자체이용Gcal), 증기(외부판매Gcal) 항목합계를 더해서 에너지회수현황(에너지이용량Gcal) 항목에 적용
      this.fireRtrvlDtlObj.vaporUsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.vaporUsqty || 0), 0);
      this.fireRtrvlDtlObj.vaporNsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.vaporNsqty || 0), 0);
      this.fireRtrvlDtlObj.htwtrUsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.htwtrUsqty || 0), 0);
      this.fireRtrvlDtlObj.htwtrNsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.htwtrNsqty || 0), 0);
      this.fireRtrvlDtlObj.prvtmUsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.prvtmUsqty || 0), 0);
      this.fireRtrvlDtlObj.prvtmNsqtyTotal = this.prfmncFireRtrvlPrdctnList.reduce((acc, item) => acc + (item.prvtmNsqty || 0), 0);

      // 증기일 경우 1MWh 당 0.86(Gcal)로 환산해주어야 함.
      this.fireRtrvlDtlObj.prvtmMWhToGcalTotal = parseFloat((this.fireRtrvlDtlObj.prvtmUsqtyTotal + this.fireRtrvlDtlObj.prvtmNsqtyTotal) * 0.86);

      this.fireRtrvlDtlObj.eneutzQtyTotal = this.sanitizeFloatingPointError(parseFloat(this.fireRtrvlDtlObj.vaporUsqtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.vaporNsqtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.htwtrUsqtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.htwtrNsqtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.prvtmMWhToGcalTotal)
      );
    },
    prfmncFireRtrvlWtCrynList() {
      this.dataSourceFireRtrvlWtCryn = { data: this.prfmncFireRtrvlWtCrynList.list, schema: { id: ['ctpvStdgCd', 'sggStdgCd']}};
      this.initializePrfmncFireRtrvlWtCrynGridColumns();
      const itemCount = this.prfmncFireRtrvlWtCrynList.list.length;
      this.gridAttrFireRtrvlWtCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlWtCryn = true;
    },
    prfmncFireRtrvlRsrcRcyclnList() {
      this.dataSourceFireRtrvlRsrcRcyclnCryn = { data: this.prfmncFireRtrvlRsrcRcyclnList.list, schema: { id: 'sggStdgCd'}};
      this.initializePrfmncFireRtrvlRsrcRcyclnGridColumns();
      const itemCount = this.prfmncFireRtrvlRsrcRcyclnList.list.length;
      this.gridAttrFireRtrvlRsrcRcyclnCryn.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlRsrcRcyclnCryn = true;
    },
    prfmncFireRtrvlNtslList() {
      this.dataSourceFireRtrvlNtsl = this.prfmncFireRtrvlNtslList.list;
      this.initializePrfmncFireRtrvlNtslGridColumns();
      const itemCount = this.prfmncFireRtrvlNtslList.list.length;
      this.gridAttrFireRtrvlNtsl.height = Math.max((itemCount * 30) + 190, 210);
      this.gridReloadFireRtrvlNtsl = true;
    },

    asstFuelInputInfo(){
      this.asstFuelInputDataSource = this.asstFuelInputInfo.list;
      this.initializeAsstFuelInputGridColumns();
      const itemCount = this.asstFuelInputInfo.list.length;
      // this.asstFuelInputGridAttr.height = (itemCount * 30) + 136;
      this.asstFuelInputGridAttr.height = Math.max((itemCount * 30) + 190, 210);
      this.asstFuelInputGridReload = true;
    },

    // 반입 그리드에서 반입량 합계가 변경되면 폐기물처리현황의 반입량항목에 적용
    'fireRtrvlDtlObj.wtStotCryqtyTotal': {
      handler(newValue) {
        this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty = newValue;
      },
      immediate: true
    },

    // 폐기물처리현황-이월량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty': 'updateWtRmnqty',
    // 폐기물처리현황-반입량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty': 'updateWtRmnqty',
    // 폐기물처리현황-전처리량 값 변경 시 잔량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty': 'updateWtRmnqty',
    // 폐기물처리현황-투입량 값 변경 시
    'fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty': {
      handler() {
        this.updateWtRmnqty(); // 폐기물처리현황의 잔량 계산
      },
      immediate: true
    },

    // 에너지회수현황-총에너지회수량 값 변경 시 에너지이용효율 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty': 'updateEneUtztnEfcnc',
    // 에너지회수현황-에너지이용량 값 변경 시 에너지이용효율 재계산
    'fireRtrvlDtlObj.eneutzQtyTotal': 'updateEneUtztnEfcnc',

    // 에너지회수현황-에너지발생량 값 변경 시 총에너지회수량 재계산
    'fireRtrvlDtlObj.eneOcrnQtyTotal': 'updateEneRtrqty',
    // 에너지회수현황-미이용 값 변경 시 총에너지회수량 재계산
    'fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty': 'updateEneRtrqty',
  },
  created() {
    this.initialize();
  },
  methods: {
    dmndRtrcnCmptn() {
      location.reload();
    },
    // 보조연료 투입 현황 행추가
    addAsstFuelInput() {
      const addRowData = {
        asstFuelInpqty: 0,
        asstFuelLcp: 0,
      };

      if (this.asstFuelInputInfo.fcltList.length === 1) {
        addRowData.fcltSqno = this.asstFuelInputInfo.fcltList[0].fcltSqno;
      }

      window.SBGrid3.addRow(this.asstFuelInputGridObject, '', addRowData);
    },
    // 보조연료 투입 현황 행삭제
    deleteAsstFuelInput() {
      const deleteAsstFuelInputKeys = window.SBGrid3.getCheckedKeys(this.asstFuelInputGridObject);

      if (deleteAsstFuelInputKeys.length === 0) {
        alert("선택된 보조연료 투입 정보가 없습니다.");
        return;
      }

      window.SBGrid3.deleteRows(this.asstFuelInputGridObject, deleteAsstFuelInputKeys);
    },
    // 시설별폐기물투입현황 - 반입량을 투입량으로 적용 버튼
    wtInpqtyAplcn() {
      /*
      1. 반입현황에서 각 항목의 합계값을 구해온다.
      2. fieldObj의 전체합계 기준 각 키의 val값의 비율을 rate에 적용한다.
      3. rate 값을 기준으로 fieldObj 각 키의 이월량 비율값을 적용한다.
      3. 각 항목별로 val(반입량) + rateBasedWtCrfqtyValue(이월량비율처리값) 값 기준 각 시설의 비율대로 합계값을 배분한다.
      4. 배분된 값을 가지고 투입현황 그리드에 적용한다.
      */

      // val : 각 항목 별 반입량 합계값
      // rate : val 기준 비율값
      // rateBasedWtCrfqtyValue : rate비율값 기준으로 이월량을 분배한 값
      let fieldObj = {
        'bplcDschrgWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
        'hmeLifeWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
        'bplcLifeWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
        'largeWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
        'wstsrtWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
        'wstwdtWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
        'wstsftWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
        'wstvnlWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
        'etcWstqty': {val: 0, rate: 0, rateBasedWtCrfqtyValue: 0},
      };

      const wtCrfqty = this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty; // 이월량

      // 반입현황 그리드에서 각 항목 별 합계값을 구함 (fieldObj의 val값이 주입됨)
      Object.keys(fieldObj).forEach(field => {
        this.totalGetAllDynamicItems(this.prfmncFireRtrvlWtCrynGridObject, field, fieldObj);
      });

      /* =================== S - fieldObj의 rate 비율 계산 =================== */
      const filteredFieldObj = Object.entries(fieldObj).filter(([, obj]) => obj.val > 0);

      // 총합 계산 (반입량 합계)
      const totalValue = filteredFieldObj.reduce((sum, [, obj]) => sum + obj.val, 0);

      // 비율 계산 및 가장 큰 비율 항목 찾기
      let maxRateKey = '';
      let maxRate = 0;

      filteredFieldObj.forEach(([key, obj]) => {
        const rawRate = (obj.val / totalValue) * 100;
        const roundedRate = parseFloat(rawRate.toFixed(2));
        fieldObj[key].rate = roundedRate;

        if (roundedRate > maxRate) {
          maxRate = roundedRate;
          maxRateKey = key;
        }
      });

      // 비율 합계 계산 및 오차 조정
      let totalRate = Object.values(fieldObj).reduce((sum, obj) => sum + obj.rate, 0);
      const diff = 100 - totalRate;

      if (diff !== 0 && maxRateKey) {
        fieldObj[maxRateKey].rate += diff;
      }
      /* =================== E - fieldObj의 rate 비율 계산 =================== */

      /* =================== S - fieldObj의 rateBasedWtCrfqtyValue 계산 =================== */
      let totalDistributedValue = 0;

      Object.keys(fieldObj).forEach(key => {
        const rate = fieldObj[key].rate;
        const rawValue = (rate / 100) * wtCrfqty;
        const roundedValue = parseFloat(rawValue.toFixed(2));

        fieldObj[key].rateBasedWtCrfqtyValue = roundedValue;
        totalDistributedValue += roundedValue;
      });

      const diffWtCrfqty = wtCrfqty - totalDistributedValue;

      if (diffWtCrfqty !== 0 && maxRateKey) {
        fieldObj[maxRateKey].rateBasedWtCrfqtyValue += diffWtCrfqty;
      }
      /* =================== E - fieldObj의 rateBasedWtCrfqtyValue 계산 =================== */

      // 시설별 용량 합계 계산
      const totalCapacity = this.sanitizeFloatingPointError(this.prfmncFclt.list.reduce((sum, item) => sum + item.dilyFcltCap, 0));

      // 각 시설에 대한 비율 계산
      const fcltRateResult = this.prfmncFclt.list.map(item => ({
        fcltSqno: item.fcltSqno,
        fcltCapRate: item.dilyFcltCap / totalCapacity,
      }));

      let changeDataList = [];

      // 비율값을 기준으로 fieldObj 키마다 값을 분배
      Object.keys(fieldObj).map(key => {
        const totalValueToDistribute = fieldObj[key].val + fieldObj[key].rateBasedWtCrfqtyValue;

        let totalDistributedValue = 0; // 분배된 값의 합계
        let maxRateFacility = null; // 가장 큰 비율을 가진 시설

        const allocation = fcltRateResult.map((facility, index) => {
          const rawValue = totalValueToDistribute * facility.fcltCapRate;
          const roundedValue = parseFloat(rawValue.toFixed(2));

          totalDistributedValue += roundedValue;

          // 가장 큰 값을 가진 시설
          if (!maxRateFacility || facility.fcltCapRate > maxRateFacility.fcltCapRate) {
            maxRateFacility = { facility, index };
          }

          // 값 추가
          changeDataList.push({
            key: facility.fcltSqno,
            field: key,
            value: roundedValue,
          });

          return {
            fcltSqno: facility.fcltSqno,
            resultValue: roundedValue,
          };
        });

        // 분배값 합계와 기준값의 차이 계산
        const diff = totalValueToDistribute - totalDistributedValue;

        // 오차를 가장 큰 비율을 가진 시설에 적용
        if (diff !== 0 && maxRateFacility) {
          const adjustmentIndex = changeDataList.findIndex(
              item =>
                  item.key === maxRateFacility.facility.fcltSqno && item.field === key
          );

          if (adjustmentIndex !== -1) {
            changeDataList[adjustmentIndex].value += diff;
          }
        }

        return { [key]: allocation };
      });

      // 분배된 값을 시설별 폐기물 투입현황 그리드에 적용
      window.SBGrid3.setValues(this.prfmncFireRtrvlInputGridObject, changeDataList);
    },

    // 그리드 내 특정 항목 데이터 합계 구하기
    totalGetAllDynamicItems(gridObj, field, resultObj){
      const allItems = window.SBGrid3.getAllItems(gridObj);
      const total = this.sanitizeFloatingPointError(allItems.reduce((sum, item) => {
        return sum + (item[field] || 0);
      }, 0));

      resultObj[field].val = total;
    },
    // 보조연료종류그룹코드(PFM007) 로드
    loadAsstFuelKndGroupCd() {
      const asstFuelKndGroupCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND_GROUP, useYn: 'Y' });
      this.asstFuelKndGroupCdList = asstFuelKndGroupCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 보조연료종류코드(PFM008) 로드
    loadAsstFuelKndCd() {
      const asstFuelKndCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_KIND, useYn: 'Y' });
      this.asstFuelKndCdList = asstFuelKndCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 보조연료단위코드(PFM009) 로드
    loadAsstFuelUnitCd() {
      const asstFuelUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.ASSISTANCE_FUEL_UNIT, useYn: 'Y' });
      this.asstFuelUnitCdList = asstFuelUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 저위발열량단위코드(PFM010) 로드
    loadLcpUnitCd() {
      const lcpUnitCdList = this.$store.getters.getCodeList({ groupCd: CODE.LOW_CALORIFIC_POWER_UNIT, useYn: 'Y' });
      this.lcpUnitCdList = lcpUnitCdList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd,
        frstRefVl: item.frstRefVl,
      }));
    },
    // 폐기물처리현황의 잔량 계산
    updateWtRmnqty() {
      let wtRmnqty = parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumCryqty) -
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.prprcsWstqty) -
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty);
      wtRmnqty = (Math.round(wtRmnqty *100)/100).toFixed(2);

      this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty = wtRmnqty;
    },
    // 에너지회수현황의 에너지이용효율 계산
    updateEneUtztnEfcnc() {
      if(!this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty || isNaN(this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty)){
        this.fireRtrvlDtlObj.prfmncFireRtrvl.eneUtztnEfcnc = 0;
      }else {
        this.fireRtrvlDtlObj.prfmncFireRtrvl.eneUtztnEfcnc =
            (parseFloat(this.fireRtrvlDtlObj.eneutzQtyTotal) / parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty)) * 100;
      }
    },
    // 에너지회수현황의 에너지이용량 계산
    updateEneUtzqty() {
      this.fireRtrvlDtlObj.eneutzQtyTotal =
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.stotUsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.stotNsqty) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.elctgnEquipInpqty);
    },
    // 에너지회수현황의 총에너지회수량 계산
    updateEneRtrqty() {
      this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty =
          parseFloat(this.fireRtrvlDtlObj.eneOcrnQtyTotal) +
          parseFloat(this.fireRtrvlDtlObj.prfmncFireRtrvl.eneUnuqty);
    },
    // 폐기물 반입현황 총 반입량 가져오기
    changeFireRtrvlWtCryn(values){
      values.forEach((item) => {
        // 그리드의 모든 데이터를 가져와서 반입량 항목의 모든 값의 합계를 계산
        const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlWtCrynGridObject);
        const total = this.sanitizeFloatingPointError(
            wtCrynAllItems.reduce((sum, item) => {
              return sum + (item.wtStotCryqty || 0);
            }, 0));

        this.fireRtrvlDtlObj.wtStotCryqtyTotal = total;

        //2025.03.17 null 인경우 0처리
        const data = window.SBGrid3.getRowData(this.prfmncFireRtrvlWtCrynGridObject, item.key);
        if(!data[item.field]) { data[item.field] = 0; }
      });
    },
    // 시설별 투입/생산 현황 변경 이벤트
    changeFireRtrvlPrdctn(values){
      values.forEach(item => {
        if (['vaporPrdctnTon', 'vaporEnthlpVl', 'vaporPrdqty', 'vaporTp', 'vaporPrssVl', 'htwtrPrdqty', 'prvtmPrdqty'].includes(item.field)) {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.prfmncFireRtrvlPrdctnGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }

        if (item.field === 'vaporPrdctnTon') { // 증기생산량(증기톤) 항목 변경 시
          // 그리드의 모든 데이터를 가져와서 증기엔탈피(kcal/kg) 항목 값 계산
          const rowData = window.SBGrid3.getRowData(this.prfmncFireRtrvlPrdctnGridObject, item.key);

          const vaporEnthlpVl = parseFloat(rowData.vaporEnthlpVl);  // 증기엔탈피
          const vaporPrdctnTon = parseFloat(item.value);            // 증기(톤)

          // 증기엔탈피 값 입력이 존재할 때 증기(톤)값이 있으면 증기생산량(Gcal) 값 적용
          // 반대도 처리 vaporEnthlpVl
          if ((vaporEnthlpVl || 0) > 0  && (vaporPrdctnTon || 0) > 0) {
            const vaporPrdqtyKcal = vaporPrdctnTon * 1000 * vaporEnthlpVl; // kcal 단위
            const vaporPrdqtyGcal = vaporPrdqtyKcal / 1000000;             // Gcal 단위
            window.SBGrid3.setValue(this.prfmncFireRtrvlPrdctnGridObject, item.key, parseFloat(vaporPrdqtyGcal?.toFixed(2)), 'vaporPrdqty');
          }

          // 그리드의 모든 데이터를 가져와서 증기생산량(Gcal) 항목의 모든 값의 합계를 계산 후 에너지회수현황 에너지발생량(Gcal) 항목에 적용
          const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
          const total = this.sanitizeFloatingPointError(
              prdctnAllItems.reduce((sum, item) => {
                return sum + (item.vaporPrdqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.eneOcrnQtyTotal = total;
        }

        //2025.02.11 증기엔탈피 수정 가능하게 됨
        if(item.field === 'vaporEnthlpVl'){ //증기엔탈피 항목 변경 시
          // 그리드의 모든 데이터를 가져와서 증기엔탈피(kcal/kg) 항목 값 계산
          const rowData = window.SBGrid3.getRowData(this.prfmncFireRtrvlPrdctnGridObject, item.key);

          const vaporEnthlpVl = parseFloat(item.value);  // 증기엔탈피
          const vaporPrdctnTon = parseFloat(rowData.vaporPrdctnTon);            // 증기(톤)

          // 증기(톤)값이 존재할 때 증기엔탈피 값 입력이 있으면 증기생산량(Gcal) 값 적용
          // 반대도 처리 vaporPrdctnTon
          if ((vaporEnthlpVl || 0) > 0  && (vaporPrdctnTon || 0) > 0) {
            const vaporPrdqtyKcal = vaporPrdctnTon * 1000 * vaporEnthlpVl; // kcal 단위
            const vaporPrdqtyGcal = vaporPrdqtyKcal / 1000000;             // Gcal 단위
            window.SBGrid3.setValue(this.prfmncFireRtrvlPrdctnGridObject, item.key, parseFloat(vaporPrdqtyGcal?.toFixed(2)), 'vaporPrdqty');
          }

          // 그리드의 모든 데이터를 가져와서 증기생산량(Gcal) 항목의 모든 값의 합계를 계산 후 에너지회수현황 에너지발생량(Gcal) 항목에 적용
          const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
          const total = this.sanitizeFloatingPointError(
              prdctnAllItems.reduce((sum, item) => {
                return sum + (item.vaporPrdqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.eneOcrnQtyTotal = total;
        }

        // 증기생산량(Gcal) 항목 변경 시 2/6 고민정 대리 요청으로 인한 추가
        // 증기 + 온수 + (전기*0.86)
        if (item.field === 'vaporPrdqty' || item.field === 'htwtrPrdqty' || item.field === 'prvtmPrdqty') {
          // 그리드의 모든 데이터를 가져와서 증기생산량(Gcal) 항목의 모든 값의 합계를 계산 후 에너지회수현황 에너지발생량(Gcal) 항목에 적용
          const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
          let total = 0;
          const vaporPrdqtyTotal = this.sanitizeFloatingPointError(
              prdctnAllItems.reduce((sum, item) => {
                return sum + (item.vaporPrdqty || 0);
              }, 0));

          const htwtrPrdqtyTotal = this.sanitizeFloatingPointError(
              prdctnAllItems.reduce((sum, item) => {
                return sum + (item.htwtrPrdqty || 0);
              }, 0));

          //전기 생산량 입력일때만 동작
          const obj = item;
          if(obj.field === 'prvtmPrdqty'){
            const rowData = window.SBGrid3.getRowData(this.prfmncFireRtrvlPrdctnGridObject, item.key);
            
            //증기 생산량(톤) 또는 증기 생산량(Gcal) 값이 존재할때 전기 생산량 값이 입력되면
            if(obj.value > 0 && (rowData.vaporPrdqty > 0 || rowData.vaporPrdctnTon > 0)){
              if(!confirm("입력하신 전기 생산량값이\n증기 생산에서 발생한 전기가 아님을 확인합니다.\n(취소 버튼을 클릭하면 0값으로 입력됩니다.)")){
                // 취소 클릭시 입력값 0으로 되도록 처리
                window.SBGrid3.setValue(this.prfmncFireRtrvlPrdctnGridObject, item.key, 0, 'prvtmPrdqty');
              }
            }
          }

          const prvtmPrdqtyTotal = this.sanitizeFloatingPointError(
                prdctnAllItems.reduce((sum, item) => {
                return sum + (item.prvtmPrdqty || 0);
              }, 0));

          const prvtmPrdqtyTotalToGal = parseFloat(prvtmPrdqtyTotal * 0.86);

          total = this.sanitizeFloatingPointError(vaporPrdqtyTotal + htwtrPrdqtyTotal + prvtmPrdqtyTotalToGal) ;
          this.fireRtrvlDtlObj.eneOcrnQtyTotal = total;

        }

        
      });
    },
    // 시설별 폐기물 투입 현황 변경 이벤트
    changeFireRtrvlInput(values){
      values.forEach(item => {
        if (['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'].includes(item.field)) {
          // 그리드의 모든 데이터를 가져와서 투입량합계 항목의 합계값 계산
          const inputAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlInputGridObject);
          const total = this.sanitizeFloatingPointError(
              inputAllItems.reduce((sum, item) => {
                return sum + (item.wtInpqtyTotal || 0);
              }, 0));

          this.fireRtrvlDtlObj.prfmncFireRtrvl.wtSumInpqty = parseFloat(total.toFixed(2));
        }

        // 그리드의 모든 데이터를 가져와서 저위발열량 평균값 계산
        const inputAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlInputGridObject);
        const filteredItems = inputAllItems.filter(item => item.fireLcp > 0);
        const sumWstqty = filteredItems.reduce((total, item) => {
          const totalWstqty =
              (item.bplcDschrgWstqty || 0) +
              (item.hmeLifeWstqty || 0) +
              (item.bplcLifeWstqty || 0) +
              (item.largeWstqty || 0) +
              (item.wstsrtWstqty || 0) +
              (item.wstwdtWstqty || 0) +
              (item.wstsftWstqty || 0) +
              (item.wstvnlWstqty || 0) +
              (item.etcWstqty || 0);
          return total + totalWstqty;
        }, 0);

        const sumWstqtyLcp = filteredItems.reduce((total, item) => {
          const totalWstqty =
              (item.bplcDschrgWstqty || 0) +
              (item.hmeLifeWstqty || 0) +
              (item.bplcLifeWstqty || 0) +
              (item.largeWstqty || 0) +
              (item.wstsrtWstqty || 0) +
              (item.wstwdtWstqty || 0) +
              (item.wstsftWstqty || 0) +
              (item.wstvnlWstqty || 0) +
              (item.etcWstqty || 0);
          const fireLcp = item.fireLcp || 0;
          return total + (totalWstqty * fireLcp);
        }, 0);
        this.fireRtrvlDtlObj.prfmncFireRtrvl.fireAvgLcp = sumWstqty > 0 ? utils.round2(sumWstqtyLcp / sumWstqty) : 0;

        //2025.03.17 null 인경우 0처리
        const data = window.SBGrid3.getRowData(this.prfmncFireRtrvlInputGridObject, item.key);
        if(!data[item.field]) { data[item.field] = 0; }
      });
    },
    //2025.03.17 시설별 보조연료 투입현황 변경 이벤트
    changeAsstFuelInput(values){
      values.forEach(item => {
        if (['asstFuelInpqty', 'asstFuelLcp'].includes(item.field)) {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.asstFuelInputGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }
      });
    },
    // 에너지 이용 현황 변경 이벤트
    changeFireRtrvlUse(values){
      // 변경된 그리드 데이터 중 증기(자체이용Gcal), 증기(외부판매Gcal) 항목이 변경된 경우 이벤트
      values.forEach(item => {
        if (['stUsgUsqtyTon', 'htwtrPrdctnUsgUsqtyTon', 'elctgnUsgUsqtyTon', 'vaporNsqtyTon', 'vaporEnthlpVl'].includes(item.field)) {

          const rowData = window.SBGrid3.getRowData(this.prfmncFireRtrvlUseGridObject, item.key);

          const vaporEnthlpVl = parseFloat(rowData.vaporEnthlpVl);  // 증기엔탈피
          const vaporUsqtyTon = parseFloat(rowData.stUsgUsqtyTon)+parseFloat(rowData.htwtrPrdctnUsgUsqtyTon)+parseFloat(rowData.elctgnUsgUsqtyTon);            // 증기(자체사용)
          const vaporNsqtyTon = parseFloat(rowData.vaporNsqtyTon);            // 증기(외부판매)

          if (vaporEnthlpVl || vaporUsqtyTon) {
            const vaporUsqtyKcal = vaporUsqtyTon * 1000 * vaporEnthlpVl; // kcal 단위
            const vaporUsqtyGcal = vaporUsqtyKcal / 1000000;             // Gcal 단위
            window.SBGrid3.setValue(this.prfmncFireRtrvlUseGridObject, item.key, parseFloat(vaporUsqtyGcal?.toFixed(2)), 'vaporUsqty');
          }

          if (vaporEnthlpVl || vaporNsqtyTon) {
            const vaporNsqtyKcal = vaporNsqtyTon * 1000 * vaporEnthlpVl; // kcal 단위
            const vaporNsqtyGcal = vaporNsqtyKcal / 1000000;             // Gcal 단위
            window.SBGrid3.setValue(this.prfmncFireRtrvlUseGridObject, item.key, parseFloat(vaporNsqtyGcal?.toFixed(2)), 'vaporNsqty');
          }


          // 그리드의 모든 데이터를 가져와서 증기(자체이용Gcal), 증기(외부판매Gcal) 항목들의 모든 값의 합계를 계산 후 에너지회수현황(에너지이용량Gcal) 항목에 적용
          const useAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlUseGridObject);
          // 증기(자체이용Gcal) 합계
          const vaporUsqtyTotal = this.sanitizeFloatingPointError(
              useAllItems.reduce((sum, item) => {
                return sum + (item.vaporUsqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.vaporUsqtyTotal = vaporUsqtyTotal;

          // 증기(외부판매Gcal) 합계
          const vaporNsqtyTotal = this.sanitizeFloatingPointError(
              useAllItems.reduce((sum, item) => {
                return sum + (item.vaporNsqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.vaporNsqtyTotal = vaporNsqtyTotal;

          // 각 항목의 모든 합계를 더해서 에너지회수현황(에너지이용량Gcal) 항목에 적용
          this.fireRtrvlDtlObj.eneutzQtyTotal = parseFloat(this.fireRtrvlDtlObj.vaporUsqtyTotal) + parseFloat(this.fireRtrvlDtlObj.vaporNsqtyTotal);
        }

        if (item.field === 'vaporUsqty' || item.field === 'vaporNsqty' || item.field === 'htwtrUsqty'|| item.field === 'htwtrNsqty'|| item.field ==='prvtmUsqty' || item.field === 'prvtmNsqty') { // 증기생산량(Gcal) 항목 변경 시 2/6 고민정 대리 요청으로 인한 추가
          // 그리드의 모든 데이터를 가져와서 증기(자체이용Gcal), 증기(외부판매Gcal) 항목들의 모든 값의 합계를 계산 후 에너지회수현황(에너지이용량Gcal) 항목에 적용
          const useAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlUseGridObject);

          // ********************************************************  증기 ******************* START 25.02.21 박재훈 추가
          // 증기(자체이용Gcal) 합계
          const vaporUsqtyTotal = this.sanitizeFloatingPointError(
              useAllItems.reduce((sum, item) => {
                return sum + (item.vaporUsqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.vaporUsqtyTotal = vaporUsqtyTotal;

          // 증기(외부판매Gcal) 합계
          const vaporNsqtyTotal = this.sanitizeFloatingPointError(
              useAllItems.reduce((sum, item) => {
                return sum + (item.vaporNsqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.vaporNsqtyTotal = vaporNsqtyTotal;

          // ********************************************************  증기 ******************* END 25.02.21 박재훈 추가

          // ********************************************************  온수 ******************* START 25.02.21 박재훈 추가
          const htwtrUsqtyTotal = this.sanitizeFloatingPointError(
              useAllItems.reduce((sum, item) => {
                return sum + (item.htwtrUsqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.htwtrUsqtyTotal = htwtrUsqtyTotal;

          // 증기(외부판매Gcal) 합계
          const htwtrNsqtyTotal = this.sanitizeFloatingPointError(
              useAllItems.reduce((sum, item) => {
                return sum + (item.htwtrNsqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.htwtrNsqtyTotal = htwtrNsqtyTotal;

          // ********************************************************  온수 ******************* END 25.02.21 박재훈 추가

          // ********************************************************  온수 ******************* START 25.02.21 박재훈 추가
          const prvtmUsqtyTotal = this.sanitizeFloatingPointError(
              useAllItems.reduce((sum, item) => {
                return sum + (item.prvtmUsqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.prvtmUsqtyTotal = prvtmUsqtyTotal;

          // 증기(외부판매Gcal) 합계
          const prvtmNsqtyTotal = this.sanitizeFloatingPointError(
              useAllItems.reduce((sum, item) => {
                return sum + (item.prvtmNsqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.prvtmNsqtyTotal = prvtmNsqtyTotal;

          // 증기일 경우 1MWh 당 0.86(Gcal)로 환산해주어야 함.
          this.fireRtrvlDtlObj.prvtmMWhToGcalTotal = parseFloat((prvtmUsqtyTotal + prvtmNsqtyTotal) * 0.86);

          // ********************************************************  온수 ******************* END 25.02.21 박재훈 추가


          // 각 항목의 모든 합계를 더해서 에너지회수현황(에너지이용량Gcal) 항목에 적용
          this.fireRtrvlDtlObj.eneutzQtyTotal = this.sanitizeFloatingPointError(parseFloat(this.fireRtrvlDtlObj.vaporUsqtyTotal)
              + parseFloat(this.fireRtrvlDtlObj.vaporNsqtyTotal)
              + parseFloat(this.fireRtrvlDtlObj.htwtrUsqtyTotal)
              + parseFloat(this.fireRtrvlDtlObj.htwtrNsqtyTotal)
              + parseFloat(this.fireRtrvlDtlObj.prvtmMWhToGcalTotal)
          );
        }

        /*'vaporUsqty'*/

        if (['vaporEnthlpVl', 'stUsgUsqtyTon', 'htwtrPrdctnUsgUsqtyTon', 'elctgnUsgUsqtyTon', 'vaporUsqty', 'vaporNsqtyTon', 'vaporNsqty', 'htwtrUsqty', 'htwtrNsqty', 'prvtmUsqty', 'prvtmNsqty'].includes(item.field)) {
          //2025.03.17 null 인경우 0처리
          const data = window.SBGrid3.getRowData(this.prfmncFireRtrvlUseGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }

      });
    },

    // 소각 판매현황 총 판매량 가져오기
    changeFireRtrvlNtsl(values){
      //2025.03.17 null 인경우 0처리
      values.forEach(item => {
        if (['vaporTonNsqty', 'vaporNsqty', 'htwtrNsqty', 'prvtmNsqty'].includes(item.field)) {
          const data = window.SBGrid3.getRowData(this.prfmncFireRtrvlNtslGridObject, item.key);
          if(!data[item.field]) { data[item.field] = 0; }
        }
      });

      if (this.isUpdating) return;  // 이벤트 중복 실행 방지
      this.isUpdating = true;  // 이벤트 시작

      // 변경된 그리드 데이터 중 판매량이 변경된 경우 이벤트
      values.forEach(item => {
        if (item.field === 'htsrcNsqty') {
          // 그리드의 모든 데이터를 가져와서 열 판매량 항목의 모든 값의 합계를 계산 후 에너지이용현황(외부공급-열 공급) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = this.sanitizeFloatingPointError(
              ntslAllItems.reduce((sum, item) => {
                return sum + (item.htsrcNsqty || 0);
              }, 0));

          this.fireRtrvlDtlObj.prfmncFireRtrvl.htsrcSumNsqty = total;
        }
        if (item.field === 'prvtmNsqty') {
          // 그리드의 모든 데이터를 가져와서 전력 판매량 항목의 모든 값의 합계를 계산 후 전력발전 생산/이용 현황(외부판매량) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = this.sanitizeFloatingPointError(
              ntslAllItems.reduce((sum, item) => {
                return sum + (item.prvtmNsqty || 0);
              }, 0));

          this.fireRtrvlDtlObj.prfmncFireRtrvl.prvtmSumNsqty = total;
        }
        if (item.field === 'etcEneNsqty') {
          // 그리드의 모든 데이터를 가져와서 기타 판매량 항목의 모든 값의 합계를 계산 후 에너지이용현황(외부공급-기타) 항목에 적용
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
          const total = this.sanitizeFloatingPointError(
              ntslAllItems.reduce((sum, item) => {
                return sum + (item.etcEneNsqty || 0);
              }, 0));

          this.fireRtrvlDtlObj.prfmncFireRtrvl.etcUsgSumNsqty = total;
        }

        // 증기(Gcal) 입력이 될 시 증기(톤) 값 0으로 반환
        if(item.field === 'vaporNsqty'){
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);

          for(const col of ntslAllItems) {
            const currentTon = window.SBGrid3.getValue(this.prfmncFireRtrvlNtslGridObject, col._key_, 'vaporTonNsqty');

            if (currentTon !== 0) {
              window.SBGrid3.setValue(this.prfmncFireRtrvlNtslGridObject, col._key_, 0, 'vaporTonNsqty');

            }

          }
        }

        // 증기(톤) 입력이 될 시 증기(Gcal) 값 0으로 반환
        if(item.field === 'vaporTonNsqty'){
          const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);

          for(const col of ntslAllItems) {
            const currentNs = window.SBGrid3.getValue(this.prfmncFireRtrvlNtslGridObject, col._key_, 'vaporNsqty');

            if (currentNs !== 0) {
              window.SBGrid3.setValue(this.prfmncFireRtrvlNtslGridObject, col._key_, 0, 'vaporNsqty');

            }
          }

        }

        
      });

      setTimeout(() => {
        this.isUpdating = false;
      }, 100);
    },
    async initialize() {
      await this.loadPrfmncSttsCd();
      this.loadAsstFuelKndGroupCd();
      this.loadAsstFuelKndCd();
      this.loadAsstFuelUnitCd();
      this.loadLcpUnitCd();
    },
    // 공통코드(실적상태코드) 로드
    async loadPrfmncSttsCd() {
      const prfmncSttsList = this.$store.getters.getCodeList({groupCd: CODE.PERFORMANCE_STATUS, useYn: 'Y'});
      this.prfmncSttsList = prfmncSttsList.map(item => ({
        text: item.dtlCdNm,
        value: item.dtlCd
      }));
    },
    // 폐기물 반입현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlWtCrynGridColumns() {
      this.gridColumnsFireRtrvlWtCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '반입량 합계(톤)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00'
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          type: 'number',
          dataType: 'number',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00'
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsrtWstqty',
              caption: '폐합성수지류(톤)',
              width: 100,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstwdtWstqty',
              caption: '폐목재(톤)',
              width: 100,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsftWstqty',
              caption: '폐섬유(톤)',
              width: 100,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            }
          ]
        }
      ]
    },
    // 자원순환정보시스템 반입처리 등록현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlRsrcRcyclnGridColumns() {
      this.gridColumnsFireRtrvlRsrcRcyclnCryn = [
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '반입',
          width: 200,
          part: 'head',
          columns : [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 100,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 100,
              editable: false,
            }
          ],
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          field: 'wtStotCryqty',
          caption: '반입량 합계(톤)',
          unit: '%',
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          calc: {
            require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'],
            eval: 'sum',
            nullAs: 0,
            dataType: 'number',
          },
          format: '#,##0.00'
        },
        {
          field: 'bplcDschrgWstqty',
          caption: '배출사업장계(톤)',
          width: 100,
          align: 'right',
          dataType: 'number',
          editable: false,
          colCss: 'grid-disabled',
          total: {
            aggregates: [
              { func: 'sum', nullAs: 0 },
            ],
            header: [
              { template: '{{sum}}', format: '#,##0.00', align: 'right' }
            ],
            headerCss:'grid-header',
          },
          format: '#,##0.00'
        },
        {
          caption: '종량제에 의한 혼합배출',
          columns : [
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            }
          ]
        },
        {
          caption: '종량제 외 배출',
          columns : [
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsrtWstqty',
              caption: '폐합성수지류(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstwdtWstqty',
              caption: '폐목재(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsftWstqty',
              caption: '폐섬유(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 100,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            }
          ]
        }
      ]
    },
    // 시설별 폐기물 투입 그리드 컬럼 세팅
    initializePrfmncFireRtrvlInputGridColumns() {
      this.gridColumnsFireRtrvlInput = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        {
          field: 'fcltNm',
          caption: '시설명',
          width: 300,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계' , align: 'right'}
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '투입량(톤)',
          columns: [
            {
              field: 'wtInpqtyTotal',
              caption: '투입량합계',
              width: 150,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              calc: {
                require: ['bplcDschrgWstqty', 'hmeLifeWstqty', 'bplcLifeWstqty', 'largeWstqty', 'wstsrtWstqty', 'wstwdtWstqty', 'wstsftWstqty', 'wstvnlWstqty', 'etcWstqty'],
                eval: 'sum',
                nullAs: 0,
                dataType: 'number',
              },
              format: '#,##0.00',
            },
            {
              field: 'bplcDschrgWstqty',
              caption: '배출사업장계(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'hmeLifeWstqty',
              caption: '가정생활(톤)', // 구 생활폐기물(톤)
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'bplcLifeWstqty',
              caption: '사업장생활(톤)', // 구 사업장생활(톤)
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'largeWstqty',
              caption: '대형폐기물(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsrtWstqty',
              caption: '폐합성수지류(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstwdtWstqty',
              caption: '폐목재(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstsftWstqty',
              caption: '폐섬유(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'wstvnlWstqty',
              caption: '영농폐비닐(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
            {
              field: 'etcWstqty',
              caption: '기타(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00'
            },
          ]
        },
        {
          field: 'fireLcp',
          caption: '저위발열량(kcal/kg)',
          width: 150,
          align: 'right',
          type: 'number',
          dataType: 'number',
          total: {
            aggregates: [
              { func: (items) => {
                  const filteredItems = items.filter(item => item.fireLcp > 0);
                  const sumWstqty = filteredItems.reduce((total, item) => {
                    const totalWstqty =
                        (item.bplcDschrgWstqty || 0) +
                        (item.hmeLifeWstqty || 0) +
                        (item.bplcLifeWstqty || 0) +
                        (item.largeWstqty || 0) +
                        (item.wstsrtWstqty || 0) +
                        (item.wstwdtWstqty || 0) +
                        (item.wstsftWstqty || 0) +
                        (item.wstvnlWstqty || 0) +
                        (item.etcWstqty || 0);
                    return total + totalWstqty;
                  }, 0);

                  const sumWstqtyLcp = filteredItems.reduce((total, item) => {
                    const totalWstqty =
                        (item.bplcDschrgWstqty || 0) +
                        (item.hmeLifeWstqty || 0) +
                        (item.bplcLifeWstqty || 0) +
                        (item.largeWstqty || 0) +
                        (item.wstsrtWstqty || 0) +
                        (item.wstwdtWstqty || 0) +
                        (item.wstsftWstqty || 0) +
                        (item.wstvnlWstqty || 0) +
                        (item.etcWstqty || 0);
                    const fireLcp = item.fireLcp || 0;
                    return total + (totalWstqty * fireLcp);
                  }, 0);

                  return sumWstqty > 0 ? utils.round2(sumWstqtyLcp / sumWstqty) : 0;
                },
                field: 'fireLcpAvg'
              }
            ],
            header: [{ template: '{{fireLcpAvg}}', format: '#,##0.00' }],
            headerCss:'grid-header',
          },
          format: '#,##0.00'
        },
        {
          caption: '투입량검증', captionCss:'gridh-bg-validation',
          columns : [
            {
              field: 'mndlyFcltCap',
              caption: '월간시설용량(톤/월)',
              captionCss:'gridh-bg-validation',
              width: 150,
              align: 'right',
              dataType: 'number',
              editable: false,
              colCss: 'grid-disabled',
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              visible: false,
            },
            {
              field: 'sggStdgNm',
              caption: '초과용량(톤)',
              captionCss:'gridh-bg-validation',
              width: 150,
              align: 'right',
              dataType: 'number',
              editable: false,
              total: {
                aggregates: [
                  { func: 'sum', nullAs: 0 },
                ],
                header: [
                  { template: '{{sum}}', format: '#,##0.00', align: 'right' }
                ],
                headerCss:'grid-header',
              },
              getValue: (value, field, rowItem) => {
                const wtInpqtyTotal = rowItem.data.wtInpqtyTotal || 0;
                const mndlyFcltCap = rowItem.data.mndlyFcltCap || 0;

                return (parseFloat(wtInpqtyTotal) - parseFloat(mndlyFcltCap)) < 0 ? 0 : parseFloat(wtInpqtyTotal) - parseFloat(mndlyFcltCap);
              },
              colCss: (data) => {
                const wtInpqtyTotal = data.wtInpqtyTotal || 0;
                const mndlyFcltCap = data.mndlyFcltCap || 0;

                if (wtInpqtyTotal > mndlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-good';
                }
              },
              format: '#,##0.00',
              visible: false,
            },
            {
              field: 'vrfcRslt',
              caption: '검증결과',
              captionCss:'gridh-bg-validation',
              width: 150,
              editable: false,
              getValue: (value, field, rowItem) => {
                const wtInpqtyTotal = rowItem.data.wtInpqtyTotal || 0;
                const mndlyFcltCap = rowItem.data.mndlyFcltCap || 0;

                return wtInpqtyTotal > mndlyFcltCap ? "초과" : "적합";
              },
              colCss: (data) => {
                const wtInpqtyTotal = data.wtInpqtyTotal || 0;
                const mndlyFcltCap = data.mndlyFcltCap || 0;

                if (wtInpqtyTotal > mndlyFcltCap) {
                  return 'grid-danger';
                } else {
                  return 'grid-good';
                }
              },
              total: {
                aggregates: [
                ],
                header: [
                  { template: ''}
                ],
                headerCss:'grid-header',
              },
              visible: false,
            }
          ],
        },
      ]
    },
    initializeAsstFuelInputGridColumns() {
      this.asstFuelInputGridColumns = [
        { field: 'fcltSqno', caption: '시설명', width: 200,
          type: 'combo',
          items: this.asstFuelInputInfo.fcltList,
          comboLabel: 'fcltNm',
          comboValue: 'fcltSqno',
        },
        { caption: '보조연료 정보',
          columns: [
            { field: 'asstFuelKndGroupCd', caption: '연료구분', width: 150,
              type: 'combo',
              items: this.asstFuelKndGroupCdList,
              comboLabel: 'text',
              comboValue: 'value',
            },
            { field: 'asstFuelKndCd', caption: '연료종류', width: 150,
              type: 'combo',
              items: this.asstFuelKndCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelKndCn', caption: '기타종류명', width: 150,
              editable: (rowData) => rowData.asstFuelKndGroupCd === '99',
              getValue: (value, field, rowItem) => {
                return rowItem.data.asstFuelKndGroupCd === '99' ? value : ''
              },
              setValue: (value, field, rowItem) => {
                return rowItem.data.asstFuelKndGroupCd === '99' ? value : ''
              },
              colCss: (data) => {
                return data.asstFuelKndGroupCd === '99' ? '' : 'grid-disabled'
              }
            },
            { field: 'asstFuelInpqty', caption: '연료투입량', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'asstFuelUnitCd', caption: '연료단위', width: 150,
              type: 'combo',
              items: this.asstFuelUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelKndGroupCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
            { field: 'asstFuelLcp', caption: '저위발열량', type: 'number', dataType: 'number', align: 'right', format: '#,##0.00', width: 150 },
            { field: 'lcpUnitCd', caption: '저위발열량 단위', unit: '%',
              type: 'combo',
              items: this.lcpUnitCdList,
              comboLabel: 'text',
              comboValue: 'value',
              master: 'asstFuelUnitCd',
              masterField: 'frstRefVl',
              useFilter: true,
            },
          ]
        },
      ]
    },
    // 에너지생산 현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlPrdctnGridColumns() {
      this.gridColumnsFireRtrvlPrdctn = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        {
          field: 'fcltNm',
          caption: '시설명',
          width: 300,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '증기', captionCss:'gridh-bg-vapor',
          columns: [
            {
              field: 'vaporPrdctnTon',
              caption: '증기생산량(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporEnthlpVl',
              caption: '증기엔탈피(kcal/kg)',
              editable: true,
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              // colCss: 'grid-disabled',
              total: {
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporPrdqty',
              caption: '증기생산량(Gcal)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              /*editable: false, 2/6 고민정 대리 요청으로 입력가능하도록 처리 */
              /*colCss: 'grid-disabled',*/
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporTp',
              caption: '증기온도(°C)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporPrssVl',
              caption: '증기압력(kgf/㎠)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-vapor',
            },
          ],
        },
        {
          caption: '온수', captionCss:'gridh-bg-hot-water',
          columns: [{
            field: 'htwtrPrdqty',
            caption: '온수생산량(Gcal)',
            width: 150,
            align: 'right',
            type: 'number',
            dataType: 'number',
            total: {
              aggregates: [
                {func: 'sum', nullAs: 0},
              ],
              header: [
                {template: '{{sum}}', format: '#,##0.00', align: 'right'}
              ],
              headerCss:'grid-header',
            },
            format: '#,##0.00',
            captionCss:'gridh-bg-hot-water',
          }]
        },
        {
          caption: '전기', captionCss:'gridh-bg-electricity',
          columns: [{
            field: 'prvtmPrdqty',
            caption: '전기생산량(MWh)',
            width: 150,
            align: 'right',
            type: 'number',
            dataType: 'number',
            total: {
              aggregates: [
                {func: 'sum', nullAs: 0},
              ],
              header: [
                {template: '{{sum}}', format: '#,##0.00', align: 'right'}
              ],
              headerCss:'grid-header',
            },
            format: '#,##0.00',
            captionCss:'gridh-bg-electricity',
          }]
        },
      ]
    },
    // 에너지이용 현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlUseGridColumns() {
      this.gridColumnsFireRtrvlUse = [
        { field: 'fcltSqno', caption: '시설순번', visible: false, editable: false },
        {
          field: 'fcltNm',
          caption: '시설명',
          width: 300,
          align: 'left',
          part: 'head',
          editable: false,
          total: {
            aggregates: [
            ],
            header: [
              { template: '계', align: 'right' }
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '에너지 이용 정보',
          columns: [
            {
              caption: '증기(증기톤)', captionCss:'gridh-bg-vapor',
              columns: [
                {
                  field: 'vaporEnthlpVl',
                  caption: '증기엔탈피(kcal/kg)',
                  width: 150,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  editable: true,
                  // colCss: 'grid-disabled',
                  total: {
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },{
                  caption: '자체이용(톤)', captionCss:'gridh-bg-vapor',
                  columns: [
                    {
                      field: 'stUsgUsqtyTon',
                      caption: '스팀이용(증기톤)',
                      width: 150,
                      align: 'right',
                      type: 'number',
                      dataType: 'number',
                      total: {
                        aggregates: [
                          {func: 'sum', nullAs: 0},
                        ],
                        header: [
                          {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                        ],
                        headerCss:'grid-header',
                      },
                      format: '#,##0.00',
                      captionCss:'gridh-bg-vapor',
                    },
                    {
                      field: 'htwtrPrdctnUsgUsqtyTon',
                      caption: '온수이용(증기톤)',
                      width: 150,
                      align: 'right',
                      type: 'number',
                      dataType: 'number',
                      total: {
                        aggregates: [
                          {func: 'sum', nullAs: 0},
                        ],
                        header: [
                          {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                        ],
                        headerCss:'grid-header',
                      },
                      format: '#,##0.00',
                      captionCss:'gridh-bg-vapor',
                    },
                    {
                      field: 'elctgnUsgUsqtyTon',
                      caption: '발전이용(증기톤)',
                      width: 150,
                      align: 'right',
                      type: 'number',
                      dataType: 'number',
                      total: {
                        aggregates: [
                          {func: 'sum', nullAs: 0},
                        ],
                        header: [
                          {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                        ],
                        headerCss:'grid-header',
                      },
                      format: '#,##0.00',
                      captionCss:'gridh-bg-vapor',
                    },
                  ]
                },
                {
                  field: 'vaporUsqty',
                  caption: '자체이용(Gcal) (A)',
                  width: 150,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  //editable: false,
                  //colCss: 'grid-disabled',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
                {
                  field: 'vaporNsqtyTon',
                  caption: '외부판매(톤)',
                  width: 150,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  colCss: () => {
                    return this.isVaporDiff ? '' : 'grid-warning'
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
                {
                  field: 'vaporNsqty',
                  caption: '외부판매(Gcal) (B)',
                  width: 150,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  //2025.02.11 입력가능하도록 수정(고민정 대리 요청)
                  //editable: false,
                  //colCss: 'grid-disabled',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-vapor',
                },
              ]
            },
            {
              caption: '온수(Gcal)', captionCss:'gridh-bg-hot-water',
              columns: [
                {
                  field: 'htwtrUsqty',
                  caption: '자체이용 (C)',
                  width: 150,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-hot-water',
                },
                {
                  field: 'htwtrNsqty',
                  caption: '외부판매 (D)',
                  width: 150,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  colCss: () => {
                    return this.isHtwtrDiff ? '' : 'grid-warning'
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-hot-water',
                },
              ]
            },
            {
              caption: '전기(MWh)', captionCss:'gridh-bg-electricity',
              columns: [
                {
                  field: 'prvtmUsqty',
                  caption: '자체이용 (E)',
                  width: 150,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-electricity',
                },
                {
                  field: 'prvtmNsqty',
                  caption: '외부판매 (F)',
                  width: 150,
                  align: 'right',
                  type: 'number',
                  dataType: 'number',
                  total: {
                    aggregates: [
                      {func: 'sum', nullAs: 0},
                    ],
                    header: [
                      {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                    ],
                    headerCss:'grid-header',
                  },
                  colCss: () => {
                    return this.isPrvtmDiff ? '' : 'grid-warning'
                  },
                  format: '#,##0.00',
                  captionCss:'gridh-bg-electricity',
                },
              ]
            },
          ],
        },
      ]
    },
    // 에너지 외부판매 현황 그리드 컬럼 세팅
    initializePrfmncFireRtrvlNtslGridColumns() {
      this.gridColumnsFireRtrvlNtsl = [
        { field: 'eneBzentySn', caption: '에너지업체일련번호', visible: false, editable: false },
        { field: 'ctpvStdgCd', caption: '시도법정동코드', visible: false, editable: false },
        { field: 'sggStdgCd', caption: '시군구법정동코드', visible: false, editable: false },
        {
          caption: '판매 업체',
          width: 700,
          part: 'head',
          columns: [
            {
              field: 'ctpvStdgNm',
              caption: '시·도',
              width: 150,
              editable: false,
            },
            {
              field: 'sggStdgNm',
              caption: '시·군·구',
              width: 150,
              editable: false,
            },
            {
              field: 'bzentyNm',
              caption: '업체명',
              minWidth: 250,
              width: 100,
              unit:'%',
              align: 'left',
              editable: false,
            },
          ],
          total: {
            aggregates: [],
            header: [
              {template: '계', align: 'right'}
            ],
            headerCss:'grid-header',
          },
        },
        {
          caption: '외부판매량',
          columns: [
            {
              field: 'vaporTonNsqty',
              caption: '증기(톤)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}',
                    align: 'right',
                    format: '#,##0.00',
                  }
                ],
                headerCss:'grid-header',
              },
              colCss: () => {
                return this.isVaporDiff ? '' : 'grid-warning'
              },

              //format: '#,##0.00',
              format: (value) => {
                return value.toFixed(2).replace(/d(?=(d{3})+.)/g, '$&,');
              },
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'vaporNsqty',
              caption: '증기(Gcal)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}',
                    align: 'right',
                    format: '#,##0.00',
                  }
                ],
                headerCss:'grid-header',
              },
              colCss: () => {
                return this.isVaporDiff ? '' : 'grid-warning'
              },

              //format: '#,##0.00',
              format: (value) => {
                return value.toFixed(2).replace(/d(?=(d{3})+.)/g, '$&,');
              },
              captionCss:'gridh-bg-vapor',
            },
            {
              field: 'htwtrNsqty',
              caption: '온수(Gcal)',
              width: 150,
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              colCss: () => {
                return this.isHtwtrDiff ? '' : 'grid-warning'
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-hot-water',
            },
            {
              field: 'prvtmNsqty',
              caption: '전기(MWh)',
              width: '150',
              align: 'right',
              type: 'number',
              dataType: 'number',
              total: {
                aggregates: [
                  {func: 'sum', nullAs: 0},
                ],
                header: [
                  {template: '{{sum}}', format: '#,##0.00', align: 'right'}
                ],
                headerCss:'grid-header',
              },
              colCss: () => {
                return this.isPrvtmDiff ? '' : 'grid-warning'
              },
              format: '#,##0.00',
              captionCss:'gridh-bg-electricity',
            },
          ]
        }
      ]
    },
    gridLoadedFireRtrvlWtCryn() {
      this.gridReloadFireRtrvlWtCryn = false;
    },
    gridLoadedFireRtrvlRsrcRcyclnCryn() {
      this.gridReloadFireRtrvlRsrcRcyclnCryn = false;
    },
    gridLoadedFireRtrvlInput() {
      this.gridReloadFireRtrvlInput = false;
    },
    asstFuelInputGridLoaded() {
      this.asstFuelInputGridReload = false;
    },
    gridLoadedFireRtrvlPrdctn() {
      this.loadGridAutoVaporPrdctn();
      this.gridReloadFireRtrvlPrdctn = false;
    },
    gridLoadedFireRtrvlUse() {
      this.gridReloadFireRtrvlUse = false;
    },
    gridLoadedFireRtrvlNtsl() {
      this.gridReloadFireRtrvlNtsl = false;
    },
    formatNumber(number) {
      return utils.formatNumberWithComma(number);
    },

    // 실적보고상세저장 호출
    async prtmncRptDtlSaveChk(isSubmit = false){

      let prfmncSttsCd = "IPG"; // 저장 시 기본 작성중 상태처리
      const validationMessage = await this.validate(true);

      if(validationMessage){
        alert(validationMessage);
        return;
      }

      if (!isSubmit && !confirm("저장하시겠습니까?")) {
        return;
      }else if (isSubmit && !confirm("제출하시겠습니까?")) {
        return;
      }

      if(isSubmit){
        prfmncSttsCd = "SUB"; // 제출 시 실적상태코드 제출코드로 전송함
      }

      try {
        const result = await this.checkFirePrcsPrfmc(prfmncSttsCd);
        if (result.change) {
          storeSwitch.on('FireRtrvlPrcsPrfmncChgDscntPopup', {dataList: result.list, prfmncMst: this.prfmncMst});
          return;
        }
      } catch (e) {
        alert("오류가 발생했습니다.");
        return;
      }

      /* 소각은 제출과 승인을 진행하지 않으므로 주석처리
      if (!validationMessage) {
        if (confirm("필수입력 조건이 만족하여 제출가능한상태입니다.\n제출하시겠습니까?")) {
          prfmncSttsCd = "SUB"; // 제출 시 실적상태코드 제출코드로 전송함
        }else{
          if(isSubmit){
            return;
          }
        }
      }
      */

      await this.prtmncRptDtlSave(prfmncSttsCd);
    },
    // 실적보고상세저장 호출
    async prtmncRptDtlSave(prfmncSttsCd, changeList = []){

      // const { updated: wtCrynUpdated } = window.SBGrid3.getSaveData(this.prfmncFireRtrvlWtCrynGridObject, false, true, false);
      // const { updated: ntslUpdated } = window.SBGrid3.getSaveData(this.prfmncFireRtrvlNtslGridObject, false, true, false);
      // const { updated: prdctnUpdated } = window.SBGrid3.getSaveData(this.prfmncFireRtrvlPrdctnGridObject, false, true, false);
      // const { updated: inputUpdated } = window.SBGrid3.getSaveData(this.prfmncFireRtrvlInputGridObject, false, true, false);
      // const { updated: useUpdated } = window.SBGrid3.getSaveData(this.prfmncFireRtrvlUseGridObject, false, true, false);

      /* 2025.02.03 미가동시설도 실적등록할 수 있도록 디폴트로 조회된 0값도 저장 되도록 수정  */
      const wtCrynUpdated = window.SBGrid3.getAllItems(this.prfmncFireRtrvlWtCrynGridObject);
      const ntslUpdated = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject);
      const prdctnUpdated = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
      const inputUpdated = window.SBGrid3.getAllItems(this.prfmncFireRtrvlInputGridObject);
      const useUpdated = window.SBGrid3.getAllItems(this.prfmncFireRtrvlUseGridObject);

      const { inserted, updated, deleted } = window.SBGrid3.getSaveData(this.asstFuelInputGridObject);
      const asstFuelInputList = [...inserted, ...updated, ...deleted];
      const prdUpdated = this.arrMergeData(prdctnUpdated, useUpdated);

      this.$apiCall.post(
          API.PRFMNC_DTL_SAVE,
          {
            wtCrynUpdated,
            ntslUpdated,
            // prdctnUpdated,
            inputUpdated,
            // useUpdated,
            prdUpdated,
            prfmncMst: Object.assign({}, this.fireRtrvlDtlObj.prfmncMst,{ prfmncSttsCd: prfmncSttsCd }),
            prfmncFireRtrvl: this.fireRtrvlDtlObj.prfmncFireRtrvl,
            asstFuelInputList: asstFuelInputList,
            prfmncSttsCd,
            changeList
          },
          (data) => {
            alert((prfmncSttsCd === "SUB" ? '제출' : '저장') + "이 완료되었습니다.");
            storeSwitch.off('FireRtrvlPrcsPrfmncChgDscntPopup');
            let routerChuck = {path: ROUTE.PERFORMANCE_LIST};

            if(prfmncSttsCd !== "SUB") {
              routerChuck = {path: ROUTE.PERFORMANCE_DTL, query: {prfmncId: data.result.prfmncId}};
            }

            this.$router.push(routerChuck)
                .then(() => prfmncSttsCd !== "SUB" && location.reload())
                .catch((err) => {
                  console.error('Navigation failed:', err);
                });
          },
          () => {
            alert((prfmncSttsCd === "SUB" ? '제출' : '저장') + "에 실패하였습니다.");
          }
      );
    },
    validate(focusOnError = false) {
      // 폐기물반입현황 - 반입량합계 필수값 체크
      const wtCrynAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlWtCrynGridObject);
      const totalWtStotCryqty = this.sanitizeFloatingPointError(
          wtCrynAllItems.reduce((sum, item) => {
            return sum + (item.wtStotCryqty || 0);
          }, 0));
      // if(totalWtStotCryqty === 0) {
      // 2025.02.03 실적값을 0기입해도 저장 및 제출 가능하도록 수정
      if(totalWtStotCryqty == null) {
        if (focusOnError) {
          this.$refs.prfmncDtlFireRtrvlWtCrynGridTitle.focus();
        }
        return "폐기물 반입 정보를 입력해주세요.";
      }

      // 잔량이 0보다 작은 경우 체크
      if(this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty < 0){
        if (focusOnError) {
          this.$refs.prfmncDtlFireRtrvlWtTitle.focus();
        }
        return "투입량(+전처리량)이 반입량(+이월량)보다 초과할 수 없습니다.";
      }

      // 시설별 폐기물 투입 현황 - 투입량합계 필수값 체크
      const inputAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlInputGridObject);
      const totalWtInpqtyTotal = this.sanitizeFloatingPointError(
          inputAllItems.reduce((sum, item) => {
            return sum + (item.wtInpqtyTotal || 0);
          }, 0));

      // if(totalWtInpqtyTotal === 0) {
      // 2025.02.03 실적값을 0기입해도 저장 및 제출 가능하도록 수정
      if(totalWtInpqtyTotal == null) {
        if (focusOnError) {
          this.$refs.prfmncDtlFireRtrvlInputTitle.focus();
        }
        return "시설별 폐기물 투입 현황 정보를 입력해주세요.";
      }

      /********************* 박재훈 추가 25.02.22 START ***/
      if (this.fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn === true || this.fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnYn === "true") {
        if(!this.fireRtrvlDtlObj.prfmncFireRtrvl.exclRsnCn){
          if (focusOnError) {
            this.$refs.exclRsnCn.focus();
          }
          return "에너지 회수, 이용량 등 계측이 불가한 시설일 경우 사유를 입력해 주시기 바랍니다.";
        }
      }else{
        //에너지 회수 현황 - 총 에너지 발생가능량(Gcal)
        if(!this.totalEneOcrnPsbltyQty){
          if (focusOnError) {
            this.$refs.eneOcrnPsbltyQtyInput.focus();
          }
          return "총 에너지 발생가능량(Gcal)은 필수입니다.\n시설별 폐기물 투입 현황의 저위발열량(kcal/kg)을 입력해주세요.";
        }

        // 에너지 회수 현황 - 총 에너지 회수량(Gcal) 필수값 체크
        /* 25-01-14 KJM 에너지회수량 필수 체크 제외
           2025.02.11 필수값 체크 다시 적용
        */
        if(!this.fireRtrvlDtlObj.prfmncFireRtrvl.eneRtrqty){
          if (focusOnError) {
            this.$refs.eneRtrqtyInput.focus();
          }
          return "총 에너지 회수량(Gcal)은 필수입니다.\n에너지 생산 현황의 증기생산량(증기톤) 및 에너지회수현황의 미이용(Gcal)을 입력해주세요.";
        }

        /**
         * 외부판매량 검증 (시설별 판매량 합계와 판매업체별 판매량 합계 일치 체크)
         */

        const useAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlUseGridObject); // 에너지이용현황 그리드
        const ntslAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlNtslGridObject); // 에너지외부판매현황 그리드

        // 에너지이용현황(증기-외부판매톤의 합계) <-> 에너지외부판매현황(외부판매량-증기 합계) 일치여부 체크
        // ※ 에너지 이용현황 total은 증기엔탈피 기입될경우 둘다 나오기 떄문에, 저장 시 외부판매 체크로직에 사용 될 것은 둘 다 변수로 할당
        // 에너지 이용현황 외부판매(톤)   total 합계
        const totalUseVaporNsqtyTon = this.sanitizeFloatingPointError(
            useAllItems.reduce((sum, item) => {
              return sum + (item.vaporNsqtyTon || 0);
            }, 0));
        // 에너지 이용현황 외부판매(Gcal) total 합계
        const totalUseVaporNsqtyGal = this.sanitizeFloatingPointError(
            useAllItems.reduce((sum, item) => {
              return sum + (item.vaporNsqty || 0);
            }, 0));

        // 에너지 외부판매 현황 외부판매(톤)   total 합계
        const totalVaporNsqtyTon = this.sanitizeFloatingPointError(
            ntslAllItems.reduce((sum, item) => {
              return sum + (item.vaporTonNsqty || 0);
            }, 0));
        // 에너지 외부판매 현황 외부판매(Gcal) total 합계
        const totalVaporNsqtyGal = this.sanitizeFloatingPointError(
            ntslAllItems.reduce((sum, item) => {
              return sum + (item.vaporNsqty || 0);
            }, 0));

        console.dir("###totalUseVaporNsqtyTon : " + totalUseVaporNsqtyTon);
        console.dir("###totalUseVaporNsqtyGal : " + totalUseVaporNsqtyGal);
        console.dir("###totalVaporNsqtyTon : " + totalVaporNsqtyTon);
        console.dir("###totalVaporNsqtyGal : " + totalVaporNsqtyGal);

        // 에너지 이용 현황에 증기(톤), 증기(Gcal) 존재 시 에너지 외부판매 현황 그리드 항목과 값이 통일 되어야 함.

        // ※※고민정 대리 요청으로 증기(톤), 증기(Gcal)을 추가하였으나, 기입은 둘중 하나만 하도록 요청함.
        // ※※외부판매 톤만 기입했을 경우 이용현황 톤과 비교 or 외부판매 Gcal만 기입했을 경우 이용현횡 Gcal과 비교

        // 증기(톤) 합산이 0일 때 증기(Gcal)로만 외부판매 = 이용현황 비교 진행
        if(totalVaporNsqtyGal > 0 && totalVaporNsqtyTon == 0){
          if(!Math.abs(totalUseVaporNsqtyGal - totalVaporNsqtyGal) < Number.EPSILON){
            if (focusOnError) {
              this.isVaporDiff = false;
              this.$refs.prfmncDtlFireRtrvlUseTitle.focus();

              if(useAllItems?.length || 0 > 0){
                const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlUseGridObject, 0);
                const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlUseGridObject, 'vaporNsqty');
                window.SBGrid3.moveFocus(this.prfmncFireRtrvlUseGridObject, rowItem, column);
              }

              if(ntslAllItems?.length || 0 > 0){
                const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlNtslGridObject, 0);
                const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlNtslGridObject, 'vaporNsqty');
                window.SBGrid3.moveFocus(this.prfmncFireRtrvlNtslGridObject, rowItem, column);
              }
            }
            return "시설별 외부판매 합계값과 판매업체별 외부판매 합계값은 일치해야 합니다.(이용현황[증기(Gal)] - 외부판매[증기(Gal)] 불일치)";
          }
        }else{
          // 증기(Gcal) 합산이 0일 때 증기(톤)로만 외부판매 = 이용현황 비교 진행
          if(!Math.abs(totalUseVaporNsqtyTon - totalVaporNsqtyTon) < Number.EPSILON){
            if (focusOnError) {
              this.isVaporDiff = false;
              this.$refs.prfmncDtlFireRtrvlUseTitle.focus();

              if(useAllItems?.length || 0 > 0){
                const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlUseGridObject, 0);
                const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlUseGridObject, 'vaporNsqtyTon');
                window.SBGrid3.moveFocus(this.prfmncFireRtrvlUseGridObject, rowItem, column);
              }

              if(ntslAllItems?.length || 0 > 0){
                const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlNtslGridObject, 0);
                const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlNtslGridObject, 'vaporTonNsqty');
                window.SBGrid3.moveFocus(this.prfmncFireRtrvlNtslGridObject, rowItem, column);
              }
            }
            return "시설별 외부판매 합계값과 판매업체별 외부판매 합계값은 일치해야 합니다.(이용현황[증기(톤)] - 외부판매[증기(톤)] 불일치)";
          }
        }

        /*if(!Math.abs(totalVaporNsqtyTon - totalVaporNsqty) < Number.EPSILON){
          if (focusOnError) {
            this.isVaporDiff = false;
            this.$refs.prfmncDtlFireRtrvlUseTitle.focus();

            if(useAllItems?.length || 0 > 0){
              const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlUseGridObject, 0);
              const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlUseGridObject, 'vaporNsqtyTon');
              window.SBGrid3.moveFocus(this.prfmncFireRtrvlUseGridObject, rowItem, column);
            }

            if(ntslAllItems?.length || 0 > 0){
              const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlNtslGridObject, 0);
              const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlNtslGridObject, 'vaporNsqty');
              window.SBGrid3.moveFocus(this.prfmncFireRtrvlNtslGridObject, rowItem, column);
            }
          }
          return "시설별 외부판매 합계값과 판매업체별 외부판매 합계값은 일치해야 합니다.(이용 - 외부판매 증기(톤) 불일치)";
        }*/

        // 박재훈 추가
        /*if(!Math.abs(totalVaporNsqtyTon - totalVaporNsqty) < Number.EPSILON){
          if (focusOnError) {
            this.isVaporDiff = false;
            this.$refs.prfmncDtlFireRtrvlUseTitle.focus();

            if(useAllItems?.length || 0 > 0){
              const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlUseGridObject, 0);
              const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlUseGridObject, 'vaporNsqtyTon');
              window.SBGrid3.moveFocus(this.prfmncFireRtrvlUseGridObject, rowItem, column);
            }

            if(ntslAllItems?.length || 0 > 0){
              const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlNtslGridObject, 0);
              const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlNtslGridObject, 'vaporNsqty');
              window.SBGrid3.moveFocus(this.prfmncFireRtrvlNtslGridObject, rowItem, column);
            }
          }
          return "시설별 외부판매 합계값과 판매업체별 외부판매 합계값은 일치해야 합니다.(이용 - 외부판매 증기(톤) 불일치2)";
        }*/

        // 에너지이용현황(온수-외부판매의 합계) <-> 에너지외부판매현황(외부판매량-온수의 합계) 일치여부 체크
        const totalUseHtwtrNsqty =this.sanitizeFloatingPointError(
            useAllItems.reduce((sum, item) => {
              return sum + (item.htwtrNsqty || 0);
            }, 0));
        const totalNtslHtwtrNsqty = this.sanitizeFloatingPointError(
            ntslAllItems.reduce((sum, item) => {
              return sum + (item.htwtrNsqty || 0);
            }, 0));
        if(!Math.abs(totalUseHtwtrNsqty - totalNtslHtwtrNsqty) < Number.EPSILON){
          if (focusOnError) {
            this.isHtwtrDiff = false;
            this.$refs.eneRtrqtyInput.focus();

            if(useAllItems?.length || 0 > 0){
              const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlUseGridObject, 0);
              const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlUseGridObject, 'htwtrNsqty');
              window.SBGrid3.moveFocus(this.prfmncFireRtrvlUseGridObject, rowItem, column);
            }

            if(ntslAllItems?.length || 0 > 0){
              const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlNtslGridObject, 0);
              const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlNtslGridObject, 'htwtrNsqty');
              window.SBGrid3.moveFocus(this.prfmncFireRtrvlNtslGridObject, rowItem, column);
            }
          }
          return "시설별 외부판매 합계값과 판매업체별 외부판매 합계값은 일치해야 합니다.(온수 불일치)";
        }

        // 에너지이용현황(전기-외부판매의 합계) <-> 에너지외부판매현황(외부판매량-전기의 합계) 일치여부 체크
        const totalUsePrvtmNsqty = this.sanitizeFloatingPointError(
            useAllItems.reduce((sum, item) => {
              return sum + (item.prvtmNsqty || 0);
            }, 0));
        const totalNtslPrvtmNsqty = this.sanitizeFloatingPointError(
            ntslAllItems.reduce((sum, item) => {
              return sum + (item.prvtmNsqty || 0);
            }, 0));
        if(!Math.abs(totalUsePrvtmNsqty - totalNtslPrvtmNsqty) < Number.EPSILON){
          if (focusOnError) {
            this.isPrvtmDiff = false;
            this.$refs.eneRtrqtyInput.focus();

            if(useAllItems?.length || 0 > 0){
              const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlUseGridObject, 0);
              const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlUseGridObject, 'prvtmNsqty');
              window.SBGrid3.moveFocus(this.prfmncFireRtrvlUseGridObject, rowItem, column);
            }

            if(ntslAllItems?.length || 0 > 0){
              const rowItem = window.SBGrid3.getRowByIndex(this.prfmncFireRtrvlNtslGridObject, 0);
              const column = window.SBGrid3.getColumnByField(this.prfmncFireRtrvlNtslGridObject, 'prvtmNsqty');
              window.SBGrid3.moveFocus(this.prfmncFireRtrvlNtslGridObject, rowItem, column);
            }
          }
          return "시설별 외부판매 합계값과 판매업체별 외부판매 합계값은 일치해야 합니다.(전기 불일치)";
        }
      }
      /********************* 박재훈 추가 25.02.22 END ***/

      const { inserted, updated } = window.SBGrid3.getSaveData(this.asstFuelInputGridObject, true, true, false);
      const asstFuelInputList = [...inserted, ...updated];

      for (const item of asstFuelInputList) {
        if (!item.fcltSqno) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'fcltSqno');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 시설을 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.asstFuelKndGroupCd) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelKndGroupCd');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 연료구분을 선택하지 않은 데이터가 있습니다.";
          }
        }

        if (!item.asstFuelKndCd) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelKndCd');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            return "보조연료 투입 현황의 연료종류를 선택하지 않은 데이터가 있습니다.";
          }
        }

        // if (!item.asstFuelInpqty) {
        // 2025.02.03 실적값을 0기입해도 저장 및 제출 가능하도록 수정
        if (item.asstFuelInpqty == null) {
          if (focusOnError) {
            this.$refs.asstFuelTitle.focus();
            const rowItem = window.SBGrid3.getRow(this.asstFuelInputGridObject, item._key_);
            const column = window.SBGrid3.getColumnByField(this.asstFuelInputGridObject, 'asstFuelInpqty');

            window.SBGrid3.moveFocus(this.asstFuelInputGridObject, rowItem, column);
            window.SBGrid3.columnEditable(this.asstFuelInputGridObject, item._key_, column, true);
            // return "보조연료 투입 현황의 연료투입량이 0이거나 입력되지 않은 데이터가 있습니다.";
            return "보조연료 투입 현황의 연료투입량이 입력되지 않은 데이터가 있습니다.";
          }
        }
      }

      return "";
    },
    checkFirePrcsPrfmc(prfmncSttsCd) {
      return new Promise((resolve, reject) => {

        const wtCrfqty = this.fireRtrvlDtlObj.prfmncFireRtrvl.wtCrfqty; // 이월량
        const wtRmnqty = this.fireRtrvlDtlObj.prfmncFireRtrvl.wtRmnqty; // 잔량

        this.$apiCall.post(
            API.USE_PROCESS_PERFORMANCE_CHANGE_CHECK,
            {
              bplcId: this.fireRtrvlDtlObj.prfmncMst.bplcId,
              prfmncYm: this.fireRtrvlDtlObj.prfmncMst.prfmncYm,
              wtCrfqty: wtCrfqty,
              wtRmnqty: wtRmnqty,
              updatePrfmncSttsCd: prfmncSttsCd,
            },
            (data) => {
              resolve(data.result);
            },
            () => {
              alert("제출에 실패하였습니다.");
              reject();
            }
        );
      });
    },
    // 자원순환정보시스템 정보적용
    rsrcRcyclnCrynInfoApply(){
      // 그리드1과 그리드2의 데이터 가져오기
      const grid1Data = window.SBGrid3.getAllItems(this.prfmncFireRtrvlWtCrynGridObject);
      const grid2Data = window.SBGrid3.getAllItems(this.prfmncFireRtrvlRsrcRcyclnCrynGridObject);

      if(!grid2Data || (grid2Data?.length || 0) === 0){
        alert("자원순환정보시스템의 반입정보가 존재하지 않아 적용할 수 없습니다.");
        return;
      }

      // 그리드2 데이터 맵 생성 (키값으로 빠르게 접근)
      const grid2DataMap = new Map(
          grid2Data.map(item => [`${item.ctpvStdgCd}_${item.sggStdgCd}`, item])
      );

      // 그리드1 데이터를 반복하면서 그리드2와 비교
      grid1Data.forEach((grid1Item) => {
        const key = `${grid1Item.ctpvStdgCd}_${grid1Item.sggStdgCd}`;

        if (grid2DataMap.has(key)) {
          const grid2Item = grid2DataMap.get(key);

          // 그리드2에 있는 데이터로 덮어쓰기 전 변경이 있는지 확인
          const isModified = this.compareObjects(grid1Item, grid2Item);
          if (isModified) {
            this.updatedWtCrynData.push({ ...grid2Item });
            Object.assign(grid1Item, grid2Item);
            // grid1Data.splice(index, 1, { ...grid2Item }); // splice()로 교체
          }
        }
      });

      // 그리드2에만 있는 데이터는 그리드1에 추가
      grid2Data.forEach(item => {
        const key = `${item.ctpvStdgCd}_${item.sggStdgCd}`;

        if (!grid1Data.some(grid1Item => `${grid1Item.ctpvStdgCd}_${grid1Item.sggStdgCd}` === key)) {
          // 그리드1에 존재하지 않으면 데이터 추가
          this.insertedWtCrynData.push( item );
          grid1Data.push({ ...item });
          // this.$set(grid1Data, grid1Data.length, { ...item }); // $set을 사용하여 반응성 유지
        }
      });

      window.SBGrid3.setClientData(this.prfmncFireRtrvlWtCrynGridObject, grid1Data);

      //자원순환정보시스템 정보적용 버튼 클릭 시 폐기물처리현황그리드의 반입량이 자동변경되지 않아 추가  2025.03.06 lhs
      this.$nextTick(() => {
        this.fireRtrvlDtlObj.wtStotCryqtyTotal = this.sanitizeFloatingPointError(
            grid1Data.reduce((sum, item) => {
              return sum + (item.wtStotCryqty || 0);
            }, 0));
      });
    },

    compareObjects(obj1, obj2) {
      const allKeys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])];

      const differences = allKeys.filter(key => {
        if (key === '_key_') return false; // _key_ 속성은 비교에서 제외
        return obj1[key] !== obj2[key];
      });

      return differences.length > 0;
    },
    arrMergeData(arr1, arr2){
      const mergedResult = [];

      // 덮어쓰기 대상 항목
      const overwriteFields = [
        "stUsgUsqtyTon",
        "htwtrPrdctnUsgUsqtyTon",
        "elctgnUsgUsqtyTon",
        "vaporUsqty",
        "vaporNsqtyTon",
        "vaporNsqty",
        "htwtrUsqty",
        "htwtrNsqty",
        "prvtmUsqty",
        "prvtmNsqty"
      ];

      arr1.forEach(obj1 => {
        // 기준 키(bplcId + fcltSqno)를 생성
        const key = `${obj1.bplcId}-${obj1.fcltSqno}`;

        // useUpdated에서 매칭되는 객체를 찾음
        const obj2 = arr2.find(obj => `${obj.bplcId}-${obj.fcltSqno}` === key);

        if (obj2) {
          // obj1과 obj2를 비교하고 병합
          const allKeys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])];
          const mergedObj = {};

          allKeys.forEach(key => {
            if (key === "_key_") {
              // _key_는 obj1에서 유지
              mergedObj[key] = obj1[key];
            } else if (overwriteFields.includes(key)) {
              // 덮어쓰기 대상 항목은 obj2에서 가져옴
              mergedObj[key] = obj2[key] !== undefined ? obj2[key] : obj1[key];
            } else {
              // 나머지 항목은 obj1의 값을 유지
              mergedObj[key] = obj1[key];
            }
          });

          mergedResult.push(mergedObj);
        } else {
          // useUpdated에 없는 경우 obj1만 추가
          mergedResult.push({ ...obj1 });
        }
      });

      // useUpdated에서 prdctnUpdated에 없는 객체 추가
      arr2.forEach(obj2 => {
        const key = `${obj2.bplcId}-${obj2.fcltSqno}`;
        const exists = arr1.some(obj1 => `${obj1.bplcId}-${obj1.fcltSqno}` === key);

        if (!exists) {
          mergedResult.push({ ...obj2 });
        }
      });

      return mergedResult;
    },

    // 소수점 둘쨰자리 오류 수정
    // 부동소수점(floating point) 연산의 정밀도 문제 스크립트 고유 버그
    sanitizeFloatingPointError(value){
      if (typeof value !== "number") return value; // 숫자가 아닐 경우 반환

      // 소수점 2자리까지만 유지하고 반올림하여 부동소수점 오차 제거
      return parseFloat((Math.round(value * 100) / 100).toFixed(2));

    },

    // 페이지 로드 시 증기생산량(톤), 증기엔탈피(kcal/kg) 확인된 값으로 증기생산량(Gcal) 그리드 재 산정 25/2/6 박재훈 추가함.
    loadGridAutoVaporPrdctn(){
      // created 후 실행
      const prfmncFireRtrvlPrdctnGridList = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);

      for(const col of prfmncFireRtrvlPrdctnGridList) {
        const vaporEnthlpVl  = parseFloat(col.vaporEnthlpVl);  // 증기엔탈피(kcal/kg)
        const vaporPrdctnTon = parseFloat(col.vaporPrdctnTon); // 증기생산량(톤)

        // 유효성 검사
        if (!vaporEnthlpVl || !vaporPrdctnTon || vaporEnthlpVl <= 0 || vaporPrdctnTon <= 0) {
          // 그리드의 모든 데이터를 가져와서 증기생산량(Gcal) 항목의 모든 값의 합계를 계산 후 에너지회수현황 에너지발생량(Gcal) 항목에 적용
          const prdctnAllItems = window.SBGrid3.getAllItems(this.prfmncFireRtrvlPrdctnGridObject);
          const total = this.sanitizeFloatingPointError(
              prdctnAllItems.reduce((sum, item) => {
                return sum + (item.vaporPrdqty || 0);
              }, 0));
          this.fireRtrvlDtlObj.eneOcrnQtyTotal = total;

          return;
        }

        const vaporPrdqtyKcal = vaporPrdctnTon * 1000 * vaporEnthlpVl; // kcal 단위
        const vaporPrdqtyGcal = vaporPrdqtyKcal / 1000000;             // Gcal 단위

        // 값 환산 후
        window.SBGrid3.setValue(this.prfmncFireRtrvlPrdctnGridObject, col._key_, parseFloat(vaporPrdqtyGcal?.toFixed(2)), 'vaporPrdqty');
      }
    },
    // 팝업 show 함수
    callInfoPopup(){
      storeSwitch.on('infoPopup');
    },
    callEnergyPopup(){
      storeSwitch.on('energyPopup');
    },

  }
}
</script>

<style scoped>
  .checkbox_30{
    width : 25px;
    height : 25px;
    margin-bottom:7px;
  }
  .label_23{font-size: 23px;}
  .textarea_10{padding: 10px 0 0 10px;}
  .cursor_pt{cursor: pointer;}
  .ref{
    padding-left: 20px;
    color: #fd5435;
  }
  .bounce {
      font-size: 15px;
      display: inline-block;
      animation: bounce 2s infinite;
    }

    @keyframes bounce {
      0%, 100% {
        transform: translateY(1);
      }
      50% {
        transform: translateY(-3px);
      }
    }
</style>