<template>
  <div class="sch_form_wrap">
    <div class="sch_form row2">
      <span>
        <label>업체명</label>
        <br-form-input label="업체명" v-model="searchParam.bzentyNm" disabled/>
      </span>
      <span>
        <label>사업장명</label>
        <br-form-select label="사업장명" v-model:value="searchParam.bplcId" :options="bplcOptions" @change="changeBplc" panel="panel" required/>
      </span>
      <span v-if="searchParam.bplcKndCd==='01-01'">
        <label>시설명</label>
        <br-form-select label="시설명" v-model:value="searchParam.fcltSqno" :options="fcltSqnoOptions" @change="changeFclt" panel="panel"/>
      </span>
      <span v-if="searchParam.bplcKndCd==='01-03'">
        <label>검사ID</label>
        <br-form-select label="검사ID" v-model:value="searchParam.inspSn" :options="inspOptions" @change="getBplcInfo" panel="panel"/>
      </span>
    </div>
  </div>
  <div v-if="searchParam.bplcId && (searchParam.fcltSqno || searchParam.inspSn)" class="cont_box" >
    <div align="center" class="title mt_35" style="display: flex; flex-direction: column; align-items: center;">
      <h3>
        <br-form-radio label="시설 종류" :options="bplcKndOption" v-model:value="bplcParam.bplcKndCd"  panel="panel"/>
      </h3>
      <h1 class="mb_25" >외부 품질표시검사 현황</h1>
    </div>
    <div class="board_write mb_25">
      <table>
        <caption></caption>
        <colgroup>
            <col style="width:15%">
            <col style="width:35%">
            <col style="width:15%">
            <col style="width:35%">
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">품질명세서 첨부파일</th>
            <td colspan="3">
              <div style="white-space: nowrap; display: flex; justify-content: center;">
                <br-attach-file 
                  label="품질명세서 첨부파일"
                  atchFileTaskSeCd="FSM"
                  v-model:atchFileGroupId="atchFileGroupId"
                  v-model:fileProcessingData="param.fileProcessingData"
                  :files="files" 
                  panel="panel" 
                  required 
                  ref="atchFile"
                />
                <br-button label="OCR 적용" class="xsm ml_5"  @click="showOcrYnPopup()"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="searchParam.bplcId && (searchParam.fcltSqno || searchParam.inspSn)" class="board_write">
      <table>
        <colgroup>
            <col style="width:8%">
            <col style="width:14%">
            <col style="width:35%">
            <col style="width:14%">
            <col style="width:70%">
        </colgroup>
        <tbody>
          <tr>
            <th scope="row" rowspan="4" style="text-align: center; border-right:1px solid #e0e0e0">고형연료 제품 수입ㆍ제조사</th>
            <th scope="row">상호</th>
            <td>{{ bplcParam.bplcNm }}</td>
            <th scope="row">대표자성명</th>
            <td>{{ bplcParam.rprsvNm }}</td>
          </tr>
          <tr>
            <th scope="row">사업장 소재지</th>
            <td v-if="bplcParam.untyZip"> [{{ bplcParam.untyZip }}] {{ bplcParam.bplcAddr }} {{ bplcParam.bplcDaddr }}</td>
            <td v-else>{{ bplcParam.bplcAddr }} {{ bplcParam.bplcDaddr }}</td>
            <th scope="row">전화번호</th>
            <td>{{ bplcParam.telno }}</td>
          </tr>
          <tr>
            <th scope="row">수입ㆍ제조신고 번호</th>
            <td colspan="3" style="text-align: left">{{ bplcParam.dclrNo }}</td>
          </tr>
          <tr>
            <th scope="row">제조국ㆍ제조업체</th>
            <td colspan="3" style="text-align: left">{{ bplcParam.bzentyNm }}</td>
          </tr>
          <tr>
            <th scope="row" rowspan="10" style="text-align: center; border-right:1px solid #e0e0e0">고형연료 제품 품질내용</th>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>발급번호</th>
            <td>
              <br-form-input class="w200" label="발급번호" panel="panel" required v-model="param.qltyIndctIssuNo" :maxlength="20"/>
            </td>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사기관</th>
            <td>
              <br-form-select label="검사기관" v-model:value="param.qltyIndctInsofcCd" :options="insofcOtions" panel="panel" required/>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>검사일자</th>
            <td>
              <br-date-picker label="검사일자" v-model:value="param.inspBgngYmd" panel="panel" required class="mr_10"/>
            </td>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>발급일자</th>
            <td>
              <br-date-picker class="ml_10" label="발급일자" v-model:value="param.inspEndYmd" panel="panel" required/>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>종류</th>
            <td>
              <br-form-checkbox label="일반 성형" v-model:values="srfList" :options="srfCheckOption" @change="srfChange"/>
            </td>
            <td colspan="2">
              <br-form-checkbox label="바이오 성형" v-model:values="bioSrfList" :options="bioSrfCheckOption" @change="bioSrfChange"/>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>원재료의 종류 및 구성비율</th>
            <td colspan="3">
              <table>
                <colgroup>
                  <col style="width:15%"><col style="width:65%"><col style="width:20%">
                </colgroup>
                <tbody>
                  <tr>
                    <th colspan="3" scope="col">원재료 종류</th>
                  </tr>
                  <tr v-if="bplcParam.bplcKndCd=='01-03'">
                    <td colspan="3">
                      <br-form-input label="원재료종류" v-model="bplcParam.hskNm" panel="panel" required disabled style="text-align: center"/>
                    </td>
                  </tr>
                  <tr v-if="bplcParam.bplcKndCd=='01-01'">
                    <th colspan="1">원재료1</th>
                    <td colspan="1">
                      <br-form-select label="원재료종류" v-model:value="param.prdctRawmtrlCd" panel="panel" required includeSelectOption :options="prdctRawmtrlCdOptions" @change="changePrdctRawmtrl('prdctRawmtrlCd')"/>
                    </td>
                    <td colspan="1">
                      <br-form-input label="원재료비율" v-model="param.prdctRawmtrlRt" panel="panel" required align="right" unit="%" validator="number" :maxlength="4"/>
                    </td>
                  </tr>
                  <tr v-if="bplcParam.bplcKndCd=='01-01'">
                    <th colspan="1">원재료2</th>
                    <td colspan="1">
                      <br-form-select label="원재료종류" v-model:value="param.scdPrdctRawmtrlCd" panel="panel" includeSelectOption :options="prdctRawmtrlCdOptions" @change="changePrdctRawmtrl('scdPrdctRawmtrlCd')"/>
                    </td>
                    <td colspan="1">
                      <br-form-input label="원재료비율" v-model="param.scdPrdctRawmtrlRt" panel="panel" align="right" unit="%" validator="number" :maxlength="4"/>
                    </td>
                  </tr>
                  <tr v-if="bplcParam.bplcKndCd=='01-01'">
                    <th colspan="1">원재료3</th>
                    <td colspan="1">
                      <br-form-select label="원재료종류" v-model:value="param.thdPrdctRawmtrlCd" panel="panel" includeSelectOption :options="prdctRawmtrlCdOptions" @change="changePrdctRawmtrl('thdPrdctRawmtrlCd')"/>
                    </td>
                    <td colspan="1">
                      <br-form-input label="원재료비율" v-model="param.thdPrdctRawmtrlRt" panel="panel" align="right" unit="%" validator="number" :maxlength="4"/>
                    </td>
                  </tr>
                  <tr v-if="bplcParam.bplcKndCd=='01-01'">
                    <th colspan="1">원재료4</th>
                    <td colspan="1">
                      <br-form-select label="원재료종류" v-model:value="param.frthPrdctRawmtrlCd" panel="panel" includeSelectOption :options="prdctRawmtrlCdOptions" @change="changePrdctRawmtrl('frthPrdctRawmtrlCd')"/>
                    </td>
                    <td colspan="1">
                      <br-form-input label="원재료비율" v-model="param.frthPrdctRawmtrlRt" panel="panel" align="right" unit="%" validator="number" :maxlength="4"/>
                    </td>
                  </tr>
                  <tr v-if="bplcParam.bplcKndCd=='01-01'">
                    <th colspan="1">원재료5</th>
                    <td colspan="1">
                      <br-form-select label="원재료종류" v-model:value="param.fthPrdctRawmtrlCd" panel="panel" includeSelectOption :options="prdctRawmtrlCdOptions" @change="changePrdctRawmtrl('fthPrdctRawmtrlCd')"/>
                    </td>
                    <td colspan="1">
                      <br-form-input label="원재료비율" v-model="param.fthPrdctRawmtrlRt" panel="panel" align="right" unit="%" validator="number" :maxlength="4"/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th scope="row">모양 및 크기</th>
            <td colspan="3">
              <table>
                <colgroup>
                  <col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">비성형(체통과율)</th>
                    <th scope="col">성형(직경/가로)<br/>(비성형의 경우 체의 크기)</th>
                    <th scope="col">성형(길이/세로)<br/>(비성형의 경우 체의 크기)</th>
                  </tr>
                  <tr>
                    <td>
                      <br-form-input label="체통과율" 
                      :disabled="param.prdctKndCd === '0001' || param.prdctKndCd === '0003'" 
                      panel="panel" placeholder="" v-model="param.svpsrt" align="right" unit="%" validator="number" :maxlength="10" />
                    </td>
                    <td><br-form-input label="직경/가로" placeholder="" v-model="param.dmtrMsrmtVl" panel="panel"  align="right" unit="mm" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="길이/세로" placeholder="" v-model="param.lenMsrmtVl" panel="panel" align="right" unit="mm" validator="number" :maxlength="10"/></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>발열량 및 수분 함유량</th>
            <td colspan="3">
              <table>
                <colgroup>
                  <col style="width:16.66%"><col style="width:16.66%">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">(저위)발열량</th>
                    <th scope="col">수분 함유량</th>
                  </tr>
                  <tr>
                    <td><br-form-input label="(저위)발열량" placeholder="" v-model="param.inspQty180" required panel="panel" align="right" unit="kcal/kg" validator="number" :maxlength="10" /></td>
                    <td><br-form-input label="수분 함유량" placeholder="" v-model="param.inspQty160" required panel="panel" align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>금속성분 함유량</th>
            <td colspan="3">
              <table>
                <colgroup>
                  <col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">수은(Hg)</th>
                    <th scope="col">카드뮴(Cd)</th>
                    <th scope="col">납(pb)</th>
                    <th scope="col">비소(As)</th>
                    <th scope="col">크롬(Cr)</th>
                    <th scope="col">안티몬(Sb)</th>
                  </tr>
                  <tr>
                    <td><br-form-input label="수은(Hg)" placeholder="" v-model="param.inspQty80" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="카드뮴(Cd)" placeholder="" v-model="param.inspQty90" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="납(pb)" placeholder="" v-model="param.inspQty100" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="비소(As)" placeholder="" v-model="param.inspQty110" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="크롬(Cr)" placeholder="" v-model="param.inspQty120" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="안티몬(Sb)" placeholder="" v-model="param.inspQty10" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                  </tr>
                  <tr>
                    <th scope="col">코발트</th>
                    <th scope="col">구리</th>
                    <th scope="col">망간</th>
                    <th scope="col">니켈</th>
                    <th scope="col">탈륨</th>
                    <th scope="col">바나듐</th>
                  </tr>
                  <tr>
                    <td><br-form-input label="코발트" placeholder="" v-model="param.inspQty20" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="구리" placeholder="" v-model="param.inspQty30" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="망간" placeholder="" v-model="param.inspQty40" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="니켈" placeholder="" v-model="param.inspQty50" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="탈륨" placeholder="" v-model="param.inspQty60" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="바나듐" placeholder="" v-model="param.inspQty70" panel="panel" required align="right" unit="mg/kg" validator="number" :maxlength="10"/></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th scope="row"><i class="necessary">※<span class="sr_only">필수입력</span></i>원소성분 함유량</th>
            <td colspan="3">
              <table>
                <colgroup>
                  <col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%"><col style="width:16.66%">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">회분 함유량</th>
                    <th scope="col">염소(Cl) 함유량</th>
                    <th scope="col">황분(S) 함유량</th>
                    <th scope="col">바이오매스</th>
                  </tr>
                  <tr>
                    <td><br-form-input label="회분 함유량" placeholder="" v-model="param.inspQty170" panel="panel" required align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="염소(Cl) 함유량" placeholder="" v-model="param.inspQty140" panel="panel" required align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="황분(S) 함유량" placeholder="" v-model="param.inspQty130" panel="panel" required align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                    <td><br-form-input label="바이오매스" placeholder="" v-model="param.inspQty190" panel="panel" required align="right" unit="wt.%" validator="number" :maxlength="10"/></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th scope="row">기타</th>
            <td colspan="3"><br-form-input label="기타" placeholder="" v-model="param.etcQltyIndctInspCn" panel="panel" :maxlength="1000"/></td>
          </tr>
          <tr>
            <th scope="row">참고사항</th>
            <td colspan="3"><br-form-input label="참고사항" placeholder="" v-model="param.qltyIndctInspExplnCn" panel="panel" :maxlength="1000" /></td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
<div class="btn_area right">
  <br-button v-if="searchParam.bplcId && (searchParam.fcltSqno || searchParam.inspSn)" label="등록" format="sch" size="sm" @click="create" panel="panel" validate :auth="['C']"/>
  <br-anchor-button label="목록" size="sm" @click="navigateTo"/>
</div>
<ocr-yn-popup :ocrAplcnYn="param.ocrAplcnYn" @update:ocrAplcnYn="updateOcrAplcnYn"/>
</template>

<script>
import { storeSession } from "@/js/store/store-session";
import { storeSwitch } from "@/js/store/store-switch";
import OcrYnPopup from '@/views/sfli/popup/OcrYnPopup.vue';

const API = {
  OTST_QLTY_INDCT_RSLT: "/otsdQltyIndctRslt",
  OCR: "/file/ocr",
}

export default {
  components: { OcrYnPopup },
  data() {
    return {
      prdctRawmtrlCdOptions: [],
      userInfo : storeSession.getMemberInfo(),
      searchParam: {
        bzentyNm : '',
        bplcId: '',
        inspSn: '',
        fcltSqno : '',
      },
      bplcParam: {
        bplcNm: "",
        picNm: "",
        untyZip: "",
        bplcAddr: "",
        bplcDaddr: "",
        telno : "",
        dclrNo: "",
        bzentyNm: "",
        bplcKndCd : "",
        hskNm: "",
      },
      atchFileGroupId: "",
      param : {
        qltyIndctInsofcCd : "",
        bplcId : "",
        fcltSqno : null,
        qltyIndctIssuNo: "",
        inspSn : "",
        bzentyNm: "",
        inspBgngYmd : "",
        inspEndYmd : "",
        prdctKndCd: "",
        hskCd: "",
        prdctRawmtrlCd: "",
        scdPrdctRawmtrlCd: "",
        thdPrdctRawmtrlCd: "",
        frthPrdctRawmtrlCd: "",
        fthPrdctRawmtrlCd: "",
        prdctRawmtrlRt: "",
        scdPrdctRawmtrlRt: "",
        thdPrdctRawmtrlRt: "",
        frthPrdctRawmtrlRt: "",
        fthPrdctRawmtrlRt: "",
        svpsrt: "",
        dmtrMsrmtVl: "",
        lenMsrmtVl: "",
        etcQltyIndctInspCn: "",
        qltyIndctInspExplnCn: "",
        atchFileGroupId: "",
        ocrAplcnYn: "N",
        fileProcessingData: {},
        
        inspQty10: "",
        inspQty20: "",
        inspQty30: "",
        inspQty40: "",
        inspQty50: "",
        inspQty60: "",
        inspQty70: "",
        inspQty80: "",
        inspQty90: "",
        inspQty100: "",
        inspQty110: "",
        inspQty120: "",
        inspQty130: "",
        inspQty140: "",
        inspQty160: "",
        inspQty170: "",
        inspQty180: "",
        inspQty190: "",
      },
      srfList: [],
      bioSrfList: [],
      bplcOptions: [],
      inspOptions: [],
      bplcKndOptions: {},
      bzentyNmOptions: {},
      files: [],
      bplcKndOption: [
        {value: '01-03', text: '수입'},
        {value: '01-01', text: '제조'},
      ],
      insofcOtions : [
        {value: '10', text: '한국산업기술시험원'},
        {value: '20', text: '한국기계연구원'},
        {value: '30', text: '한국에너지기술연구원'},
        {value: '50', text: 'FITI시험연구원'},
        {value: '60', text: '대덕분석기술연구원'},
        {value: '70', text: '한국화학융합시험연구원'},
      ],
      srfCheckOption : [
        {value: '1', text: '일반 고형연료'},
        {value: '2', text: '성형'},
        {value: '3', text: '비성형'},
      ],
      bioSrfCheckOption : [
        {value: '1', text: '바이오 고형연료'},
        {value: '2', text: '성형'},
        {value: '3', text: '비성형'},
      ],
      fcltSqnoOptions : [],
      fcltList : [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.initialize();
  },
  watch: {
      atchFileGroupId(newVal) {
        this.param.atchFileGroupId = newVal;
      }
    },
  methods: {
    async initialize() {
      //await this.getBzenty();
      await this.getBplc();
      if(this.searchParam.bplcKndCd == '01-01') {
        this.getBplcInfo();
      }
    },
    getBzenty(){
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
          API.OTST_QLTY_INDCT_RSLT + "/bzenty",
          {userId : this.userInfo.userId},
          (data) => {
            if(data.result){
              this.searchParam.bzentyNm = data.result.bzentyNm
              this.searchParam.bzentyId = data.result.bzentyId
            }
            if(data) {
              resolve();
            } else {
              reject();
            }
          }
        );
      });
    },
    getBplc(){
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
          API.OTST_QLTY_INDCT_RSLT + "/bplc",
          {userId : this.userInfo.userId},
          (data) => {
            if(data.result){
              this.bplcOptions = [{value: '', text: '선택'}];
              for (let bplc of data.result){
                if(bplc.bplcKndCd == '01-01' || bplc.bplcKndCd == '01-03'){
                  this.bplcOptions.push({value: bplc.bplcId, text: bplc.bplcNm});
                  this.bplcKndOptions[bplc.bplcId] = bplc.bplcKndCd;
                  this.bzentyNmOptions[bplc.bplcId] = bplc.bzentyNm;
                }
              }
            }
            if(data) {
              resolve();
            } else {
              reject();
            }
          }
        );
      });
    },
    changeBplc() {
      this.searchParam.fcltSqno = '';
      this.searchParam.inspSn = '';
      this.searchParam.bplcKndCd = this.bplcKndOptions[this.searchParam.bplcId];
      this.searchParam.bzentyNm = this.bzentyNmOptions[this.searchParam.bplcId];
      if(this.searchParam.bplcKndCd == '01-01'){
        this.getBplcInfo();
        this.getFcltSqno();
        this.getCd();
      }
      if(this.searchParam.bplcKndCd == '01-03'){
        this.setBplcParamNull();
        this.getInsp();
      }
    },
    getInsp(){
      this.inspOptions = [];
      this.param.inspId = "";
      this.$apiCall.get(
        API.OTST_QLTY_INDCT_RSLT + "/insp",
        {bplcId: this.searchParam.bplcId,},
        (data) => {
          this.inspOptions = [{value: '', text: '선택'}];
          for (let insp of data.result) {
            this.inspOptions.push({value: String(insp.inspSn), text: insp.inspId})
          }
        }
      );
    },
    getBplcInfo() {
      this.setBplcParamNull();
      this.$apiCall.get(
        API.OTST_QLTY_INDCT_RSLT + "/bplcInfo",
        {bplcId: this.searchParam.bplcId,
          bplcKndCd: this.searchParam.bplcKndCd,
          inspSn : this.searchParam.inspSn,
        },
        (data) => {
          if(data.result){
            const json = JSON.parse(JSON.stringify(data.result));
            this.bplcParam = json;
            this.param.hskCd = json.hskCd;
          }
        }
      );
    },
    create() {
      this.param.bplcId = this.searchParam.bplcId;
      this.param.inspSn = this.searchParam.inspSn;
      this.param.bzentyNm = this.searchParam.bzentyNm;
      if( this.param.bplcId == '' ) {
        alert('사업장을 선택해주세요.')
        return false;
      }
      if(this.searchParam.bplcKndCd == '01-03') {
        if(this.param.inspSn == null || this.param.inspSn === '') {
          alert('검사ID을 선택해주세요.')
          return false;
        }
      }
      if(this.searchParam.bplcKndCd == '01-01') {
        this.param.fcltSqno = this.searchParam.fcltSqno ? +this.searchParam.fcltSqno : null;
        if(this.param.fcltSqno == null || this.param.fcltSqno === '') {
          alert('시설을 선택해주세요.')
          return false;
        }
      }
      if( this.param.prdctKndCd == '' ) {
        alert('종류는 필수입력입니다.')
        return false;
      }
      if( (this.param.prdctRawmtrlCd && (!this.param.prdctRawmtrlRt || this.param.prdctRawmtrlRt == 0)) || 
          (this.param.scdPrdctRawmtrlCd && (!this.param.scdPrdctRawmtrlRt || this.param.scdPrdctRawmtrlRt == 0)) ||
          (this.param.thdPrdctRawmtrlCd && (!this.param.thdPrdctRawmtrlRt || this.param.thdPrdctRawmtrlRt == 0)) ||
          (this.param.frthPrdctRawmtrlCd && (!this.param.frthPrdctRawmtrlRt || this.param.frthPrdctRawmtrlRt == 0)) ||
          (this.param.fthPrdctRawmtrlCd && (!this.param.fthPrdctRawmtrlRt || this.param.frthPrdctRawmtrlRt == 0)) ) {
        alert('원재료 종류를 입력한 원재료의 비율을 입력해주세요.');
        return false;
      }
      if( (!this.param.prdctRawmtrlCd && this.param.prdctRawmtrlRt) || 
          (!this.param.scdPrdctRawmtrlCd && this.param.scdPrdctRawmtrlRt) ||
          (!this.param.thdPrdctRawmtrlCd && this.param.thdPrdctRawmtrlRt) ||
          (!this.param.frthPrdctRawmtrlCd && this.param.frthPrdctRawmtrlRt) ||
          (!this.param.fthPrdctRawmtrlCd && this.param.fthPrdctRawmtrlRt) ) {
        alert('원재료의 비율을 입력한 원재료의 종류를 입력해주세요.');
        return false;
      }
      const regex = new RegExp("^(100|[1-9]?\\d)(\\.\\d)?$");
      if( ( this.param.prdctRawmtrlRt && !regex.test(this.param.prdctRawmtrlRt)) ||
          ( this.param.scdPrdctRawmtrlRt && !regex.test(this.param.scdPrdctRawmtrlRt)) ||
          ( this.param.thdPrdctRawmtrlRt && !regex.test(this.param.thdPrdctRawmtrlRt)) ||
          ( this.param.frthPrdctRawmtrlRt && !regex.test(this.param.frthPrdctRawmtrlRt)) ||
          ( this.param.fthPrdctRawmtrlRt && !regex.test(this.param.fthPrdctRawmtrlRt)) ) {
        alert('원재료 종류의 100이하의 소수점 1자리수까지 입력 가능합니다.');
        return false;
      }
      if( this.param.inspBgngYmd > this.param.inspEndYmd ) {
        alert('발급일자가 검사일자보다 빠릅니다.')
        return false;
      }
      if(this.param.ocrAplcnYn == 'Y') {
        alert("OCR 적용 시 데이터 확인 필요합니다.");
      }
      if(confirm("등록 하시겠습니까?")) {
        let createParam = this.param;
        createParam.inspQty = [];
        for(let i = 1; i < 20; i++){
          createParam.inspQty.push(
            this.param[`inspQty${i*10}`]
          )
        }
        this.$apiCall.post(
          API.OTST_QLTY_INDCT_RSLT,
          this.param,
          () => {
            alert("저장에 성공하였습니다.");
            this.navigateTo()
          },
          () => {
            alert("저장에 실패하였습니다.");
          }
        );
      }
    },
    srfChange(){
      this.bioSrfList = []
      this.param.prdctKndCd = '';
      if(this.srfList.slice(-1)[0] == '2'){
        this.srfList = [];
        this.srfList.push('1');
        this.srfList.push('2');
        this.param.prdctKndCd = '0001';
      }
      if(this.srfList.slice(-1)[0] == '3'){
        this.srfList = [];
        this.srfList.push('1');
        this.srfList.push('3');
        this.param.prdctKndCd = '0002';
      }
      this.selChgPrdctKnd();
    },
    bioSrfChange(){
      this.srfList = []
      this.param.prdctKndCd = '';
      if(this.bioSrfList.slice(-1)[0] == '2'){
        this.bioSrfList = [];
        this.bioSrfList.push('1');
        this.bioSrfList.push('2');
        this.param.prdctKndCd = '0003';
      }
      if(this.bioSrfList.slice(-1)[0] == '3'){
        this.bioSrfList = [];
        this.bioSrfList.push('1');
        this.bioSrfList.push('3');
        this.param.prdctKndCd = '0004';
      }
      this.selChgPrdctKnd();
    },
    showOcrYnPopup() {
      if(this.$refs.atchFile.ufiles.length == 0) {
        alert('품질명세서 첨부파일을 선택해주세요.');
        return;
      }
      storeSwitch.on('OcrYnPopup');
    },
    navigateTo() {
      this.$router.push(
        {path: "/sfli/OtsdQltyIndctRslt"})
        .catch(() => {
          console.error("Navigation Error");
      });
    },
    getCd() {
      this.$apiCall.get(
        API.OTST_QLTY_INDCT_RSLT + "/cd",
        {},
        (data) => {
          if(data.result){
            this.prdctRawmtrlCdOptions = data.result;
          }
        }
      );
    },
    setBplcParamNull() {
      const bplcKeys = Object.keys(this.bplcParam);
      for (let key of bplcKeys) {
        this.bplcParam[key] = "";
      }
    },
    getFcltSqno(){
      this.fcltSqnoOptions = [{value: '', text: '선택'}];
      return new Promise((resolve, reject) => {
        this.$apiCall.get(
          API.OTST_QLTY_INDCT_RSLT + "/fcltSqno",
          {bplcId : this.searchParam.bplcId,
            userId : this.userInfo.userId
          },
          (data) => {
            if(data.result){
              for (let item of data.result){
                if(item.bplcKndCd == '01-01'){
                  this.fcltSqnoOptions.push({value: item.fcltSqno.toString(), text: item.fcltNm});
                }
              }
              // if (this.fcltSqnoOptions.length > 0) {
              //   this.searchParam.fcltSqno = this.fcltSqnoOptions[0].value;
              // }
              this.fcltList = data.result;
              this.changeFclt();
            }
            if(data) {
              resolve();
            } else {
              reject();
            }
          }
        );
      });
    },
    selChgPrdctKnd() {
      if(this.param.prdctKndCd === '0001' || this.param.prdctKndCd === '0003') {
          this.param.svpsrt = '';
      }
    },
    updateOcrAplcnYn(newVal) {
      if(this.searchParam.bplcId == null || this.searchParam.bplcId == '') {
        alert('사업장 미 선택시 OCR 적용이 불가합니다.');
        return;
      }
      if(this.bplcParam.bplcKndCd == '01-01' && (this.searchParam.fcltSqno == null || this.searchParam.fcltSqno == '')) {
        alert('시설 미 선택시 OCR 적용이 불가합니다.');
        return;
      }
      if(this.bplcParam.bplcKndCd == '01-03' && (this.searchParam.inspSn == null || this.searchParam.inspSn == '')) {
        alert('검사ID 미 선택시 OCR 적용이 불가합니다.');
        return;
      }
      this.param.ocrAplcnYn = newVal;

      alert("OCR 데이터 추출은 3~10초 가량 소요됩니다.");
      if(newVal == 'Y') {
        const atchFileVldNo = JSON.stringify(this.$refs.atchFile.ufiles[0].atchFileVldNo).replace(/"/g, '');
        this.$apiCall.get(
          `${API.OCR}/${atchFileVldNo}/specificationSheet`,
          {},
          (data) => {
            if(data) {
              try{
                const result = data.result.formList
                if(result.filter(item => item[0] === "Import").map(item=> item[2])[0] == 'True') this.bplcParam.bplcKndCd = '01-03' ;
                if(result.filter(item => item[0] === "Manufacturing").map(item=> item[2])[0] == 'True') this.bplcParam.bplcKndCd = '01-01' ;
                this.bplcParam.bplcNm = result.filter(item => item[0] === "companyName").map(item=> item[2])[0];
                this.bplcParam.picNm = result.filter(item => item[0] === "repName").map(item=> item[2])[0];
                this.bplcParam.bplcAddr = result.filter(item => item[0] === "bizAddress").map(item=> item[2])[0];
                this.bplcParam.dclrNo = result.filter(item => item[0] === "").map(item=> item[2])[0];
                if(result.filter(item => item[0] === "checkbox_01").map(item=> item[2])[0] == 'True') {
                  this.bioSrfList = []
                  this.param.prdctKndCd = '';
                  if(result.filter(item => item[0] === "checkbox_02").map(item=> item[2])[0] == 'True') {
                    this.srfList = [];
                    this.srfList.push('1');
                    this.srfList.push('2');
                    this.param.prdctKndCd = '0001';
                  }
                  if(result.filter(item => item[0] === "checkbox_03").map(item=> item[2])[0] == 'True') {
                    this.srfList = [];
                    this.srfList.push('1');
                    this.srfList.push('3');
                    this.param.prdctKndCd = '0002';
                  }
                }
                if(result.filter(item => item[0] === "checkbox_04").map(item=> item[2])[0] == 'True') {
                  this.srfList = []
                  this.param.prdctKndCd = '';
                  if(result.filter(item => item[0] === "checkbox_05").map(item=> item[2])[0] == 'True') {
                    this.bioSrfList = [];
                    this.bioSrfList.push('1');
                    this.bioSrfList.push('2');
                    this.param.prdctKndCd = '0003';
                  }
                  if(result.filter(item => item[0] === "checkbox_06").map(item=> item[2])[0] == 'True') {
                    this.bioSrfList = [];
                    this.bioSrfList.push('1');
                    this.bioSrfList.push('3');
                    this.param.prdctKndCd = '0004';
                  }
                }
                if(this.bplcParam.bplcKndCd == '01-03'){
                  this.param.hskNm = result.filter(item => item[0] === "rawMaterial").map(item=> item[2])[0];
                } else {
                  for(let object of this.prdctRawmtrlCdOptions) {
                    if(result.filter(item => item[0] === "rawMaterial").map(item=> item[2])[0].includes(object.text)) {
                      this.param.prdctRawmtrlCd = object.value;
                    }
                  }
                }
                this.param.dmtrMsrmtVl = this.ocrValidate(result.filter(item => item[0] === "width").map(item=> item[2])[0]);
                this.param.lenMsrmtVl = this.ocrValidate(result.filter(item => item[0] === "height").map(item=> item[2])[0]);
                this.param.inspQty180 = this.ocrValidate(result.filter(item => item[0] === "heatVal").map(item=> item[2])[0]);
                this.param.inspQty160 = this.ocrValidate(result.filter(item => item[0] === "moisture").map(item=> item[2])[0]);
                this.param.inspQty80 = this.ocrValidate(result.filter(item => item[0] === "hg").map(item=> item[2])[0]);
                this.param.inspQty90 = this.ocrValidate(result.filter(item => item[0] === "cd").map(item=> item[2])[0]);
                this.param.inspQty100 = this.ocrValidate(result.filter(item => item[0] === "pb").map(item=> item[2])[0]);
                this.param.inspQty110 = this.ocrValidate(result.filter(item => item[0] === "as").map(item=> item[2])[0]);
                this.param.inspQty120 = this.ocrValidate(result.filter(item => item[0] === "cr").map(item=> item[2])[0]);
                this.param.inspQty10 = this.ocrValidate(result.filter(item => item[0] === "sb").map(item=> item[2])[0]);
                this.param.inspQty20 = this.ocrValidate(result.filter(item => item[0] === "co").map(item=> item[2])[0]);
                this.param.inspQty30 = this.ocrValidate(result.filter(item => item[0] === "cu").map(item=> item[2])[0]);
                this.param.inspQty40 = this.ocrValidate(result.filter(item => item[0] === "mn").map(item=> item[2])[0]);
                this.param.inspQty50 = this.ocrValidate(result.filter(item => item[0] === "ni").map(item=> item[2])[0]);
                this.param.inspQty60 = this.ocrValidate(result.filter(item => item[0] === "ti").map(item=> item[2])[0]);
                this.param.inspQty70 = this.ocrValidate(result.filter(item => item[0] === "v").map(item=> item[2])[0]);
                this.param.inspQty170 = this.ocrValidate(result.filter(item => item[0] === "ashContent").map(item=> item[2])[0]);
                this.param.inspQty140 = this.ocrValidate(result.filter(item => item[0] === "ci").map(item=> item[2])[0]);
                this.param.inspQty130 = this.ocrValidate(result.filter(item => item[0] === "s").map(item=> item[2])[0]);
                this.param.inspQty190 = this.ocrValidate(result.filter(item => item[0] === "biomass").map(item=> item[2])[0]);
                this.param.inspEndYmd = result.filter(item => item[0] === "issueDate").map(item=> item[2])[0].match(/\d+/g)
                this.param.inspEndYmd = this.param.inspEndYmd[0] + '-'
                                      + (this.param.inspEndYmd[1].length == 2 ? this.param.inspEndYmd[1] : '0' + this.param.inspEndYmd[1]) + '-' 
                                      + (this.param.inspEndYmd[2].length == 2 ? this.param.inspEndYmd[2] : '0' + this.param.inspEndYmd[2])
                this.param.etcQltyIndctInspCn = result.filter(item => item[0] === "etc").map(item=> item[2])[0];
              } catch(e) {
                console.log(e)
                alert("OCR 처리에 실패하였습니다.");
              }
            }
          },
          () => {
            alert("OCR 처리에 실패하였습니다.");
          }
        );
      }
    },
    changeFclt() {
      // let prdctnPrdctKndCd = this.fcltList.find(item => item.fcltSqno.toString() === this.searchParam.fcltSqno.toString())?.prdctnPrdctKndCd;

      // console.log( this.searchParam.fcltSqno.toString());
      // console.log(this.fcltList);
      // console.log(prdctnPrdctKndCd);

      // this.srfList = [];
      // this.bioSrfList = [];
      // this.param.prdctKndCd = '';

      // if(prdctnPrdctKndCd === undefined) return;

      // if(prdctnPrdctKndCd === '0001') {
      //   this.srfList.push('2');
      //   this.srfChange();
      // }else if(prdctnPrdctKndCd === '0002') {
      //   this.srfList.push('3');
      //   this.srfChange();
      // }else if(prdctnPrdctKndCd === '0003') {
      //   this.bioSrfList.push('2');
      //   this.bioSrfChange();
      // }else if(prdctnPrdctKndCd === '0004') {
      //   this.bioSrfList.push('3');
      //   this.bioSrfChange();
      // }
    },
    ocrValidate(text) {
      try {
        if(text.includes('불검출') || text.includes('N.D') || text.includes('-')
          || text.includes('해당없음') || text.includes('해당') || text == '' || text == null 
          || text.includes('ND') || text.includes('ND.') || text.includes('N.D') || text.includes('N.D.')
          || text.includes('없음')
        ) {
          return 0;
        }
        if(text.includes('적합')) {
          return 
        }
        return Number(text.match(/\d+(\.\d+)?/g)[0].replace(' ', ''));
      } catch(e) {
        return 0;
      }
    },
    changePrdctRawmtrl(data) {
      if((this.param[data] == this.param.prdctRawmtrlCd && this.param.prdctRawmtrlCd != '' && data != 'prdctRawmtrlCd') || 
        (this.param[data] == this.param.scdPrdctRawmtrlCd && this.param.scdPrdctRawmtrlCd != '' && data != 'scdPrdctRawmtrlCd') ||
        (this.param[data] == this.param.thdPrdctRawmtrlCd && this.param.thdPrdctRawmtrlCd != '' && data != 'thdPrdctRawmtrlCd') ||
        (this.param[data] == this.param.frthPrdctRawmtrlCd && this.param.frthPrdctRawmtrlCd != '' && data != 'frthPrdctRawmtrlCd') ||
        (this.param[data] == this.param.fthPrdctRawmtrlCd && this.param.fthPrdctRawmtrlCd != '' && data != 'fthPrdctRawmtrlCd') ) {
        alert('이미 선택된 원재료입니다.');
        this.param[data] = '';
        }
    }
  },
}
</script>

<style scoped>
.board_write > table table td {
  text-align: center;
}
.board_write > table table th {
  text-align: center;
}
</style>